<template>
  <div class="bonus-approval-container">
    <div class="page-header">
      <h2 class="page-title">综合素质分申请审批</h2>
    </div>

    <div class="page-content">
      <!-- 筛选条件 -->
      <div class="filter-section">
        <el-form :inline="true" :model="filterForm" class="filter-form">
          <el-form-item label="学号">
            <el-input v-model="filterForm.student_number" placeholder="请输入学号" clearable></el-input>
          </el-form-item>
          <el-form-item label="申请状态">
            <el-select v-model="filterForm.status" placeholder="全部状态" clearable>
              <el-option label="待审核" value="待审核"></el-option>
              <el-option label="审核中" value="审核中"></el-option>
              <el-option label="已通过" value="已通过"></el-option>
              <el-option label="已拒绝" value="已拒绝"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="申请日期">
            <el-date-picker
              v-model="filterForm.dateRange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleFilter">查询</el-button>
            <el-button @click="resetFilter">重置</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 申请记录表格 -->
      <el-table
        v-loading="loading"
        :data="applicationList"
        style="width: 100%"
        border
        :header-cell-style="{background:'#f5f7fa', color:'#606266', fontWeight: '500'}"
        empty-text="暂无申请记录">
        <el-table-column
          prop="student_number"
          label="学号"
          width="100">
        </el-table-column>
        <el-table-column
          prop="student_name"
          label="姓名"
          width="100">
        </el-table-column>
        <el-table-column
          prop="bonus_item"
          label="加分项目"
          min-width="100">
        </el-table-column>
        <el-table-column
          prop="category_name"
          label="加分类别"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="level"
          label="级别"
          width="80">
        </el-table-column>
        <el-table-column
          prop="applied_bonus_points"
          label="申请分"
          width="70">
        </el-table-column>
        <el-table-column
          prop="real_bonus_points"
          label="实际分"
          width="70">
          <template slot-scope="scope">
            {{ scope.row.real_bonus_points !== null ? scope.row.real_bonus_points : '待定' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="application_date"
          label="申请日期"
          width="120">
        </el-table-column>
        <el-table-column
          prop="review_status"
          label="审核状态"
          width="100">
          <template slot-scope="scope">
            <el-tag :type="getStatusType(scope.row.review_status)">
              {{ scope.row.review_status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="200"
          fixed="right">
          <template slot-scope="scope">
            <el-button-group>
              <el-button
                size="mini"
                type="primary"
                @click="viewDetail(scope.row)"
                plain>
                详情
            </el-button>
            <el-button
              v-if="scope.row.review_status === '待审核' || scope.row.review_status === '审核中'"
              size="mini"
              type="success"
              @click="approveApplication(scope.row)"
              plain>
              审批
            </el-button>
            <el-button
              size="mini"
                type="danger"
                @click="deleteApplication(scope.row)"
                plain>
                删除
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>

    <!-- 详情对话框 -->
    <el-dialog
      title="申请详情"
      :visible.sync="detailDialogVisible"
      width="700px"
      :close-on-click-modal="false">
      <div v-if="currentApplication" class="detail-content">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="学号">{{ currentApplication.student_number }}</el-descriptions-item>
          <el-descriptions-item label="姓名">{{ currentApplication.student_name }}</el-descriptions-item>
          <el-descriptions-item label="加分项目">{{ currentApplication.bonus_item }}</el-descriptions-item>
          <el-descriptions-item label="加分类别">{{ currentApplication.category_name }}</el-descriptions-item>
          <el-descriptions-item label="级别">{{ currentApplication.level }}</el-descriptions-item>
          <el-descriptions-item label="申请分数">{{ currentApplication.applied_bonus_points }}</el-descriptions-item>
          <el-descriptions-item label="实际得分">
            {{ currentApplication.real_bonus_points !== null ? currentApplication.real_bonus_points : '待定' }}
          </el-descriptions-item>
          <el-descriptions-item label="申请日期">{{ currentApplication.application_date }}</el-descriptions-item>
          <el-descriptions-item label="审核状态">
            <el-tag :type="getStatusType(currentApplication.review_status)">
              {{ currentApplication.review_status }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="申请理由" :span="2">
            <div class="reason-text">{{ currentApplication.reason }}</div>
          </el-descriptions-item>
        </el-descriptions>

        <!-- 证明材料预览 -->
        <div class="attachment-section">
          <h3>证明材料</h3>
          <div class="attachment-preview">
            <el-button type="text" @click="viewFile(currentApplication.url)">查看</el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 添加图片查看器对话框 -->
    <el-dialog
      v-if="isImageVisible"
      :visible.sync="isImageVisible"
      width="95%"
      @close="handleImgClose">
      <el-image :src="imageSrc" fit="contain" class="f-width-a"></el-image>
    </el-dialog>

    <!-- 审批对话框 -->
    <el-dialog
      title="审批申请"
      :visible.sync="approveDialogVisible"
      width="500px"
      :close-on-click-modal="false">
      <div v-if="currentApplication" class="approve-content">
        <el-form :model="approveForm" :rules="approveRules" ref="approveForm" label-width="100px">
          <el-form-item label="学号">
            {{ currentApplication.student_number }}
          </el-form-item>
          <el-form-item label="姓名">
            {{ currentApplication.student_name }}
          </el-form-item>
          <el-form-item label="加分项目">
            {{ currentApplication.bonus_item }}
          </el-form-item>
          <el-form-item label="申请分数">
            {{ currentApplication.applied_bonus_points }}
          </el-form-item>
          <el-form-item label="审批结果" prop="status">
            <el-radio-group v-model="approveForm.status">
              <el-radio label="已通过">通过</el-radio>
              <el-radio label="已拒绝">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item 
            label="实际得分" 
            prop="real_bonus_points"
            v-if="approveForm.status === '已通过'">
            <el-input-number 
              v-model="approveForm.real_bonus_points" 
              :min="0" 
              :max="10" 
              :step="0.5"
              :precision="1">
            </el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="approveDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitApprove" :loading="submitting">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api";

export default {
  name: 'BonusApproval',
  data() {
    return {
      loading: false,
      submitting: false,
      applicationList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      filterForm: {
        student_number: '',
        status: '',
        dateRange: []
      },
      detailDialogVisible: false,
      approveDialogVisible: false,
      currentApplication: null,
      approveForm: {
        status: '已通过',
        real_bonus_points: 0
      },
      approveRules: {
        status: [
          { required: true, message: '请选择审批结果', trigger: 'change' }
        ],
        real_bonus_points: [
          { required: true, message: '请输入实际得分', trigger: 'blur' }
        ]
      },
      isImageVisible: false,
      imageSrc: '',
    };
  },
  created() {
    this.fetchApplications();
  },
  methods: {
    // 获取申请记录
    async fetchApplications() {
      this.loading = true;
      try {
        const params = {
          page: this.pagination.currentPage,
          page_size: this.pagination.pageSize
        };

        // 添加筛选条件
        if (this.filterForm.student_number) {
          params.student_number = this.filterForm.student_number;
        }
        if (this.filterForm.status) {
          params.status = this.filterForm.status;
        }
        if (this.filterForm.dateRange && this.filterForm.dateRange.length === 2) {
          params.start_date = this.filterForm.dateRange[0];
          params.end_date = this.filterForm.dateRange[1];
        }

        const response = await axiosInstance.get(api.bonusApplications, { 
          params,
        });
        
        if (response.data.status === 'success') {
          this.applicationList = response.data.data.applications;
          this.pagination.total = response.data.data.total;
        } else {
          this.$message.error(response.data.message || '获取申请记录失败');
        }
      } catch (error) {
        console.error('获取申请记录失败:', error);
        this.$message.error('获取申请记录失败');
      } finally {
        this.loading = false;
      }
    },
    
    // 处理筛选
    handleFilter() {
      this.pagination.currentPage = 1;
      this.fetchApplications();
    },
    
    // 重置筛选
    resetFilter() {
      this.filterForm = {
        student_number: '',
        status: '',
        dateRange: []
      };
      this.pagination.currentPage = 1;
      this.fetchApplications();
    },
    
    // 处理分页变化
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.fetchApplications();
    },
    
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.fetchApplications();
    },
    
    // 查看详情
    viewDetail(row) {
      this.currentApplication = row;
      this.detailDialogVisible = true;
    },
    
    // 审批申请
    approveApplication(row) {
      this.currentApplication = row;
      this.approveForm = {
        status: '已通过',
        real_bonus_points: row.applied_bonus_points
      };
      this.approveDialogVisible = true;
    },
    
    // 提交审批
    async submitApprove() {
      this.$refs.approveForm.validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            const data = {
              application_id: this.currentApplication.application_id,
              status: this.approveForm.status
            };
            
            if (this.approveForm.status === '已通过') {
              data.real_bonus_points = this.approveForm.real_bonus_points;
            }
            
            const response = await axiosInstance.post(api.updateBonusStatus, data);
            
            if (response.data.status === 'success') {
              this.$message.success('审批成功');
              this.approveDialogVisible = false;
              this.fetchApplications();
            } else {
              this.$message.error(response.data.message || '审批失败');
            }
          } catch (error) {
            console.error('审批失败:', error);
            this.$message.error('审批失败');
          } finally {
            this.submitting = false;
          }
        }
      });
    },
    
    // 查看文件
    viewFile(filePath) {
      if (!filePath) {
        this.$message.warning('没有可查看的文件');
        return;
      }

      let newFilePath = filePath.startsWith('/') ? filePath : '/uploads/' + filePath;
      
      // 检查文件后缀名是否为图片格式
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      const fileExtension = newFilePath.split('.').pop().toLowerCase();

      if (imageExtensions.includes(fileExtension)) {
        this.imageSrc = newFilePath;
        this.isImageVisible = true;
      } else if (fileExtension === 'pdf') {
        // 如果文件是 PDF，跳转到 PdfViewer 页面
        let pdfPath = "pdf/web/viewer.html?file=" + encodeURIComponent(newFilePath);
        window.open(pdfPath, '_blank');
      } else {
        // 其他类型文件直接下载
        this.downloadFile(filePath);
      }
    },

    // 关闭图片查看器
    handleImgClose() {
      this.isImageVisible = false;
    },

    // 下载文件
    downloadFile(url) {
      if (url) {
        const fullUrl = url.startsWith('http') ? url : `${window.location.origin}${url}`;
        window.open(fullUrl);
      } else {
        this.$message.warning('没有可下载的文件');
      }
    },
    
    // 获取文件名
    getFileName(url) {
      if (!url) return '';
      return url.split('/').pop();
    },
    
    // 获取状态标签类型
    getStatusType(status) {
      const statusMap = {
        '待审核': 'info',
        '审核中': 'warning',
        '已通过': 'success',
        '已拒绝': 'danger'
      };
      return statusMap[status] || 'info';
    },

    // 添加删除方法
    async deleteApplication(row) {
      try {
        await this.$confirm('确定要删除该申请记录吗？此操作不可恢复', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        
        const response = await axiosInstance.post(api.deleteBonusApplication, {
          application_id: row.application_id
        });
        
        if (response.data.status === 'success') {
          this.$message.success('删除成功');
          this.fetchApplications();
        } else {
          this.$message.error(response.data.message || '删除失败');
        }
      } catch (error) {
        if (error === 'cancel') return;
        console.error('删除失败:', error);
        this.$message.error('删除失败');
      }
    },
  }
};
</script>

<style scoped>
.bonus-approval-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  margin-bottom: 20px;
}

.page-title {
  font-size: 22px;
  color: #303133;
  margin: 0;
  font-weight: 500;
}

.filter-section {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.detail-content {
  padding: 10px;
}

.reason-text {
  white-space: pre-line;
  line-height: 1.5;
  color: #606266;
}

.attachment-section {
  margin-top: 25px;
  border-top: 1px solid #ebeef5;
  padding-top: 20px;
}

.attachment-section h3 {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  margin-top: 0;
  margin-bottom: 15px;
}

.attachment-preview {
  border: 1px solid #ebeef5;
  border-radius: 4px;
  padding: 15px;
  background-color: #f9f9f9;
}

.f-width-a {
  width: 100% !important;
}

.approve-content {
  padding: 10px;
}
</style> 