<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">飞行学院养成分管理平台</a>
        <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown">
              <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="scoreDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
                <i class="bi bi-clipboard-data"></i> 养成分
              </a>
              <ul class="dropdown-menu" aria-labelledby="scoreDropdown">
                <li>
                  <a class="dropdown-item" @click="navigateTo('/score_manage')">
                    <i class="bi bi-file-earmark-text"></i> 分数管理
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/score_compare')">
                    <i class="bi bi-bar-chart"></i> 数据筛选
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/apply_record')">
                    <i class="bi bi-check2-square"></i> 加分审批
                  </a>
                </li>

              </ul>
            </li>

            <li class="nav-item dropdown">
              <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
                <i class="bi bi-award"></i> 综合素质分
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a class="dropdown-item" @click="navigateTo('/bonus_approval')">
                    <i class="bi bi-award"></i> 综合素质分审批
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/bonus_stats')">
                    <i class="bi bi-bar-chart-line"></i> 综合素质分统计
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/bonus_category_manage')" v-if="role_type == 1">
                    <i class="bi bi-list-check"></i> 加分类别管理
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" v-if="role_type == 1">
              <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="personnelDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
                <i class="bi bi-people"></i> 人员管理
              </a>
              <ul class="dropdown-menu" aria-labelledby="personnelDropdown">
                <li>
                  <a class="dropdown-item" @click="navigateTo('/student_import')">
                    <i class="bi bi-cloud-upload"></i> 学员导入
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/student_manage')">
                    <i class="bi bi-person-lines-fill"></i> 学员管理
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/admin_manage')">
                    <i class="bi bi-person-badge"></i> 辅导员管理
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown" v-if="role_type == 1">
              <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="recordsDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
                <i class="bi bi-journal-text"></i> 系统管理
              </a>
              <ul class="dropdown-menu" aria-labelledby="recordsDropdown">
                <li>
                  <a class="dropdown-item" @click="navigateTo('/class_manage')">
                    <i class="bi bi-building"></i> 班级管理
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/op_history')">
                    <i class="bi bi-clock-history"></i> 操作记录
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/semester_manage')">
                    <i class="bi bi-calendar"></i> 学期管理
                  </a>
                </li>
                <li v-if="role_type == 1">
                  <a class="dropdown-item" @click="navigateTo('/category_manage')">
                    <i class="bi bi-gear"></i> 类别管理
                  </a>
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown">
              <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="studentScoreDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
                <i class="bi bi-mortarboard"></i> 学生成绩
              </a>
              <ul class="dropdown-menu" aria-labelledby="studentScoreDropdown">
                <li>
                  <a class="dropdown-item" @click="navigateTo('/student_score_main')">
                    <i class="bi bi-pencil-square"></i> 成绩管理
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/score_analysis')">
                    <i class="bi bi-graph-up"></i> 成绩对比
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="navigateTo('/vertical_analysis')">
                    <i class="bi bi-graph-up"></i> 成绩纵向对比
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown">
              <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="userDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
                <i class="bi bi-person-circle"></i> {{ username }}
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                <li>
                  <a class="dropdown-item" @click="changePassword">
                    <i class="bi bi-key"></i> 修改密码
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" @click="logout">
                    <i class="bi bi-box-arrow-right"></i> 退出
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <main class="container mt-3">
      <div class="row">
        <div class="col">
          <router-view/>
        </div>
      </div>
    </main>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      current: '',
      username: localStorage.user_id,
      role_type: localStorage.role_type,
      isMobile: false,
    }
  },
  mounted() {
    if (localStorage.token === null) {
      this.$message.warning('请先登录！')
      this.$router.push('/login')
    }
  },
  methods: {
    checkIfMobile() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
      )
    },
    navigateTo(route) {
      this.$router.push(route)
    },
    changePassword() {
      this.$router.push({path: '/reset_password'})
    },
    logout() {
      localStorage.clear()
      this.$router.push({path: '/login'})
    },
    handleDropdownClick() {
      // Handle dropdown click if necessary
    },
  },
}
</script>

<style>
/* 自定义样式 */
.finance-icon {
  background-image: url('../../assets/st.svg');
  background-size: cover;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.custom-submenu .el-submenu__title,
.custom-submenu .ant-menu-submenu-title {
  color: white !important;
}

/* 设置二级子菜单的宽度 */
.el-submenu .el-menu,
.ant-menu-submenu .ant-menu {
  min-width: 50px !important; /* 调整为你需要的宽度 */
}

.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
  min-width: 110px !important;
}

.custom-submenu .el-submenu__title,
.custom-menu-item a {
  color: white !important; /* 设置字体颜色为白色 */
  text-decoration: none; /* 移除下划线 */
}

.custom-menu-item a:hover {
  color: yellow !important; /* 设置悬停时的字体颜色 */
}

.main-content {
  margin-top: 10px; /* 减少顶部间距 */
}

.el-menu-item,
.ant-menu-item {
  display: block; /* 确保整个元素可点击 */
}

.content {
  background: #fff;
  padding: 10px; /* 减少内部填充 */
  min-height: 600px;
}

/* 垂直居中菜单项 */
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  display: flex;
  align-items: center;
  line-height: 64px; /* 设置为与 header 相同的高度 */
  height: 64px; /* 与 header 的高度相同 */
}

.menu-icon {
  vertical-align: middle;
  margin-right: 8px;
}

.menu-text {
  vertical-align: middle;
}

.large-icon {
  font-size: 20px; /* 调整一级目录图标大小 */
}

.large-text {
  font-size: 16px; /* 调整一级目录文字大小 */
}

/* 移除下划线 */
.no-underline {
  text-decoration: none;
}
</style>
