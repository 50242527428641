var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-image"},[_c('div',{staticClass:"main container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5 col-md-8 col-sm-10"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"title text-center mb-4"},[_vm._v("飞行学院准军事化管理系统")]),_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin"}},[(_vm.isLoginError)?_c('a-alert',{staticClass:"mb-4",attrs:{"type":"error","show-icon":"","message":"用户名或密码错误"}}):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'username',
                      {
                        rules: [
                          { required: true, message: '请输入手机号' },
                          { validator: _vm.handleUsername }
                        ],
                        validateTrigger: 'change'
                      }
                    ]),expression:"[\n                      'username',\n                      {\n                        rules: [\n                          { required: true, message: '请输入手机号' },\n                          { validator: handleUsername }\n                        ],\n                        validateTrigger: 'change'\n                      }\n                    ]"}],attrs:{"size":"large","type":"text","placeholder":"手机号"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'password',
                      {
                        rules: [{ required: true, message: '请输入密码' }],
                        validateTrigger: 'blur'
                      }
                    ]),expression:"[\n                      'password',\n                      {\n                        rules: [{ required: true, message: '请输入密码' }],\n                        validateTrigger: 'blur'\n                      }\n                    ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"密码"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'captcha',
                          {
                            rules: [{ required: true, message: '请输入验证码' }],
                            validateTrigger: 'blur'
                          }
                        ]),expression:"[\n                          'captcha',\n                          {\n                            rules: [{ required: true, message: '请输入验证码' }],\n                            validateTrigger: 'blur'\n                          }\n                        ]"}],attrs:{"size":"large","placeholder":"验证码"},model:{value:(_vm.captcha),callback:function ($$v) {_vm.captcha=$$v},expression:"captcha"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"safety-certificate"},slot:"prefix"})],1)],1),_c('div',{staticClass:"col-4 d-flex align-items-center"},[_c('img',{staticClass:"img-fluid cursor-pointer",staticStyle:{"height":"40px"},attrs:{"src":_vm.captchaImage,"alt":"验证码"},on:{"click":_vm.refreshCaptcha}})])])]),_c('a-form-item',{staticClass:"mt-3"},[_c('a-button',{staticClass:"login-button w-100",attrs:{"size":"large","type":"primary","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn},on:{"click":_vm.doLogin}},[_vm._v("登录 ")])],1)],1)],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }