<template>
  <div class="category-manage-container">
    <div class="page-header">
      <h2 class="page-title">综合素质分类别管理</h2>
      <el-button 
        type="primary" 
        icon="el-icon-plus" 
        @click="showAddDialog"
        class="add-button">
        添加类别
      </el-button>
    </div>

    <div class="page-content">
      <el-tree
        v-loading="loading"
        :data="categoryTree"
        node-key="category_id"
        default-expand-all
        :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span class="category-name">{{ data.category_name }}</span>
          <span class="category-score">推荐分值: {{ data.recommend_score }}</span>
          <span class="category-actions">
            <el-button
              type="text"
              size="mini"
              @click="() => showEditDialog(data)">
              编辑
            </el-button>
            <el-button
              type="text"
              size="mini"
              class="delete-btn"
              @click="() => handleDelete(data)">
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>

    <!-- 添加类别对话框 -->
    <el-dialog
      title="添加类别"
      :visible.sync="addDialogVisible"
      width="500px"
      :close-on-click-modal="false">
      <el-form :model="categoryForm" :rules="categoryRules" ref="categoryForm" label-width="100px">
        <el-form-item label="类别名称" prop="category_name">
          <el-input v-model="categoryForm.category_name" placeholder="请输入类别名称"></el-input>
        </el-form-item>
        <el-form-item label="父级类别">
          <el-cascader
            v-model="categoryForm.parent_category_id"
            :options="categoryOptions"
            :props="{ 
              checkStrictly: true,
              value: 'category_id',
              label: 'category_name',
              children: 'children'
            }"
            clearable
            placeholder="请选择父级类别">
          </el-cascader>
        </el-form-item>
        <el-form-item label="推荐分值" prop="recommend_score">
          <el-input-number 
            v-model="categoryForm.recommend_score" 
            :min="0" 
            :max="10" 
            :step="0.5"
            :precision="1">
          </el-input-number>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input 
            type="textarea" 
            v-model="categoryForm.description" 
            placeholder="请输入类别描述"
            :rows="3">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitAddCategory" :loading="submitting">确认</el-button>
      </span>
    </el-dialog>

    <!-- 编辑类别对话框 -->
    <el-dialog
      title="编辑类别"
      :visible.sync="editDialogVisible"
      width="500px"
      :close-on-click-modal="false">
      <el-form :model="categoryForm" :rules="categoryRules" ref="categoryForm" label-width="100px">
        <el-form-item label="类别名称" prop="category_name">
          <el-input v-model="categoryForm.category_name" placeholder="请输入类别名称"></el-input>
        </el-form-item>
        <el-form-item label="推荐分值" prop="recommend_score">
          <el-input-number 
            v-model="categoryForm.recommend_score" 
            :min="0" 
            :max="10" 
            :step="0.5"
            :precision="1">
          </el-input-number>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input 
            type="textarea" 
            v-model="categoryForm.description" 
            placeholder="请输入类别描述"
            :rows="3">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submitEditCategory" :loading="submitting">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api";

export default {
  name: 'BonusCategoryManage',
  data() {
    return {
      loading: false,
      submitting: false,
      categoryTree: [],
      categoryOptions: [],
      addDialogVisible: false,
      editDialogVisible: false,
      categoryForm: {
        category_id: null,
        category_name: '',
        parent_category_id: null,
        recommend_score: 0,
        description: ''
      },
      categoryRules: {
        category_name: [
          { required: true, message: '请输入类别名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        recommend_score: [
          { required: true, message: '请输入推荐分值', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    // 获取类别列表
    async fetchCategories() {
      this.loading = true;
      try {
        const response = await axiosInstance.get(api.getBonusCategories);
        
        if (response.data.status === 'success') {
          this.categoryTree = response.data.data;
          this.categoryOptions = JSON.parse(JSON.stringify(this.categoryTree));
        } else {
          this.$message.error(response.data.message || '获取类别列表失败');
        }
      } catch (error) {
        console.error('获取类别列表失败:', error);
        this.$message.error('获取类别列表失败');
      } finally {
        this.loading = false;
      }
    },
    
    // 显示添加对话框
    showAddDialog() {
      this.categoryForm = {
        category_name: '',
        parent_category_id: null,
        recommend_score: 0,
        description: ''
      };
      this.addDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.categoryForm && this.$refs.categoryForm.clearValidate();
      });
    },
    
    // 显示编辑对话框
    showEditDialog(data) {
      this.categoryForm = {
        category_id: data.category_id,
        category_name: data.category_name,
        recommend_score: data.recommend_score,
        description: data.description
      };
      this.editDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.categoryForm && this.$refs.categoryForm.clearValidate();
      });
    },
    
    // 提交添加类别
    submitAddCategory() {
      this.$refs.categoryForm.validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            // 处理级联选择器的值
            let parentId = null;
            if (this.categoryForm.parent_category_id && this.categoryForm.parent_category_id.length > 0) {
              parentId = this.categoryForm.parent_category_id[this.categoryForm.parent_category_id.length - 1];
            }
            
            const data = {
              category_name: this.categoryForm.category_name,
              parent_category_id: parentId,
              recommend_score: this.categoryForm.recommend_score,
              description: this.categoryForm.description
            };
            
            const response = await axiosInstance.post(api.addBonusCategory, data);
            
            if (response.data.status === 'success') {
              this.$message.success('添加类别成功');
              this.addDialogVisible = false;
              this.fetchCategories();
            } else {
              this.$message.error(response.data.message || '添加类别失败');
            }
          } catch (error) {
            console.error('添加类别失败:', error);
            this.$message.error('添加类别失败');
          } finally {
            this.submitting = false;
          }
        }
      });
    },
    
    // 提交编辑类别
    submitEditCategory() {
      this.$refs.categoryForm.validate(async (valid) => {
        if (valid) {
          this.submitting = true;
          try {
            const data = {
              category_id: this.categoryForm.category_id,
              category_name: this.categoryForm.category_name,
              recommend_score: this.categoryForm.recommend_score,
              description: this.categoryForm.description
            };
            
            const response = await axiosInstance.post(api.updateBonusCategory, data);
            
            
            if (response.data.status === 'success') {
              this.$message.success('更新类别成功');
              this.editDialogVisible = false;
              this.fetchCategories();
            } else {
              this.$message.error(response.data.message || '更新类别失败');
            }
          } catch (error) {
            console.error('更新类别失败:', error);
            this.$message.error('更新类别失败');
          } finally {
            this.submitting = false;
          }
        }
      });
    },
    
    // 删除类别
    async handleDelete(data) {
      try {
        await this.$confirm('此操作将永久删除该类别, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        
        const response = await axiosInstance.post(api.deleteBonusCategory, {
          category_id: data.category_id
        });
        
        if (response.data.status === 'success') {
          this.$message.success('删除类别成功');
          this.fetchCategories();
        } else {
          this.$message.error(response.data.message || '删除类别失败');
        }
      } catch (error) {
        if (error === 'cancel') return;
        console.error('删除类别失败:', error);
        this.$message.error('删除类别失败');
      }
    }
  }
};
</script>

<style scoped>
.category-manage-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.page-title {
  font-size: 22px;
  color: #303133;
  margin: 0;
  font-weight: 500;
}

.page-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.category-name {
  font-weight: 500;
}

.category-score {
  color: #909399;
  margin-left: 15px;
}

.category-actions {
  margin-left: auto;
}

.delete-btn {
  color: #F56C6C;
}

.delete-btn:hover {
  color: #f78989;
}
</style> 