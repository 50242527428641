<template>
  <div class="container p-1">
    <!-- 查询选项 -->
    <div class="row mb-2">
      <div class="col-md-12">
        <el-card class="card">
          <h3 class="card-title">查询选项</h3>
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="查询范围">
              <el-select v-model="searchForm.dimension" @change="handleDimensionChange" class="f-width"
                         placeholder="选择查询范围">
                <el-option label="指定年级" value="grade"></el-option>
                <el-option label="指定班级" value="class"></el-option>
                <el-option label="指定学生 (存在二级范围)" value="individual"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="searchForm.dimension === 'individual'" label="二级范围">
              <el-select v-model="searchForm.queryType" class="f-width" placeholder="选择二级范围">
                <el-option label="年级" value="grade"></el-option>
                <el-option label="班级" value="class"></el-option>
                <el-option label="学号" value="student_number"></el-option>
                <el-option label="姓名" value="name"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
                v-if="searchForm.dimension === 'individual' &&
                  (searchForm.queryType === 'student_number' || searchForm.queryType === 'name')"
                label="查询值">
              <el-input v-model="searchForm.queryValue" class="f-width" placeholder="输入查询值"></el-input>
            </el-form-item>
            <el-form-item v-if="searchForm.dimension === 'individual' && searchForm.queryType === 'class'"
                          label="选择班级">
              <el-select v-model="searchForm.queryValue" class="f-width" placeholder="选择班级">
                <el-option v-for="option in classes" :key="option" :label="option"
                           :value="option"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="searchForm.dimension === 'individual' && searchForm.queryType === 'grade'"
                          label="选择年级">
              <el-select v-model="searchForm.queryValue" class="f-width" placeholder="选择年级">
                <el-option v-for="option in grades" :key="option" :label="option"
                           :value="option"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="searchForm.dimension === 'class'" label="选择班级">
              <el-select v-model="searchForm.queryClassIds" multiple class="f-width" placeholder="选择班级">
                <el-option v-for="option in classes" :key="option" :label="option"
                           :value="option"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="searchForm.dimension === 'grade'" label="选择年级">
              <el-select v-model="searchForm.queryGradeIds" multiple class="f-width" placeholder="选择年级">
                <el-option v-for="option in grades" :key="option" :label="option"
                           :value="option"></el-option>
              </el-select>
            </el-form-item>
            <el-col :span="24">

              <el-form-item label="学员省份">
                <el-select v-model="searchForm.provinces" multiple placeholder="请选择省份" class="f-width">
                  <el-option
                      v-for="province in provinces"
                      :key="province"
                      :label="province"
                      :value="province">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学员航司">
                <el-select v-model="searchForm.airline_names" multiple placeholder="请选择航司" class="f-width">
                  <el-option
                      v-for="airline in airlines"
                      :key="airline"
                      :label="airline"
                      :value="airline">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="时间范围">
                <el-select v-model="searchForm.timeDimension" class="f-width" placeholder="选择时间范围">
                  <el-option label="日" value="day"></el-option>
                  <el-option label="周" value="week"></el-option>
                  <el-option label="月" value="month"></el-option>
                  <el-option label="学期" value="term"></el-option>
                  <el-option label="总计" value="total"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="起始时间" v-if="searchForm.timeDimension === 'day' ||
                searchForm.timeDimension === 'week' || searchForm.timeDimension === 'month'">
                <el-date-picker v-model="searchForm.startDate" type="date" value-format="yyyy-MM-dd"
                                class="f-width" placeholder="选择起始日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="截止时间" v-if="searchForm.timeDimension === 'day' ||
                searchForm.timeDimension === 'week' || searchForm.timeDimension === 'month'">
                <el-date-picker v-model="searchForm.endDate" type="date" value-format="yyyy-MM-dd"
                                class="f-width" placeholder="选择截止日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="选择学期" v-if="searchForm.timeDimension === 'term'">
                <el-select v-model="searchForm.selectedTerms" multiple class="f-width" placeholder="选择学期">
                  <el-option v-for="term in semesterList" :key="term.id" :label="term.semester_name"
                             :value="term.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="扣分类型">
                <el-cascader
                    ref="search_cascader"
                    size="medium"
                    :options="categories_cascader"
                    :props="{ checkStrictly: true }"
                    class="f-width" placeholder="选择类型"
                    @change="handleTypeChange"
                    clearable></el-cascader>
              </el-form-item>
              <el-button-group>
                <el-button type="primary" icon="el-icon-search " @click="handleSearch"
                           :loading="searchLoading">搜索
                </el-button>
                <el-button type="primary" icon="el-icon-document-delete" @click="exportToExcel"
                           :loading=excelLoading>Excel
                </el-button>
                <el-button type="primary" icon="el-icon-data-line" @click="compareSelected">可视化</el-button>
              </el-button-group>
            </el-col>
          </el-form>
        </el-card>
      </div>
    </div>

    <!-- 查询结果 -->
    <div class="row mt-2">
      <div class="col-md-12">
        <el-card>
          <el-table ref="result_table" :data="tableData" style="width: 100%" @sort-change="handleSortChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
                v-for="column in tableColumns"
                :key="column.prop"
                :prop="column.prop"
                :label="column.label"
                :width="column.width"
                :sortable="column.sortable ? 'custom' : false"
            ></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="primary" @click="viewDetails(scope.row)">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-wrapper">
            <el-pagination
                background
                layout="total,sizes,prev, pager, next,jumper"
                :total="total"
                :page-size="pageSize"
                :page-sizes="[10, 20, 30, 50]"
                @current-change="handlePageChange"
                @size-change="handleSizeChange"
            />
          </div>
        </el-card>
      </div>
      <!-- 详情对话框 -->
      <el-dialog title="详情" :visible.sync="detailDialogVisible" width="90%" @close="handleDetailClose">
        <el-table v-if="searchForm.dimension === 'individual'" :data="detailTableData" style="width: 100%"
                  @sort-change="handleDetailSortChange">
          <el-table-column prop="student_number" label="学号" sortable="custom"></el-table-column>
          <el-table-column prop="name" label="姓名" sortable="custom"></el-table-column>
          <el-table-column prop="category" label="类型" sortable="custom"></el-table-column>
          <el-table-column prop="sub_category" label="子类型" sortable="custom"></el-table-column>
          <el-table-column prop="score" label="分值" sortable="custom"></el-table-column>
          <el-table-column prop="remarks" label="备注"></el-table-column>
          <el-table-column prop="record_date" label="时间" sortable="custom"></el-table-column>
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <el-tag :type="getStatusType(scope.row.status)">
                {{ formatStatus(scope.row.status) }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-else :data="detailTableData" style="width: 100%">
          <el-table-column prop="category" label="类型" sortable="custom"></el-table-column>
          <el-table-column prop="total_deduction_score" label="扣分分数" sortable
                           :sort-method="sortDevName"
          ></el-table-column>
          <el-table-column prop="deduction_count" label="扣分次数" sortable></el-table-column>
          <el-table-column prop="total_addition_score" label="加分分数" sortable></el-table-column>
          <el-table-column prop="addition_count" label="加分次数" sortable></el-table-column>
        </el-table>
        <div class="pagination-wrapper">
          <el-pagination
              background
              layout="prev, pager, next"
              :total="detailTotal"
              :page-size="detailPageSize"
              :current-page.sync="detailCurrentPage"
              @current-change="handleDetailPageChange"
          />
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="detailDialogVisible = false; detail_start = ''; detail_end = '';">关闭</el-button>
      </span>
      </el-dialog>
      <!-- 对比对话框 -->
      <el-dialog title="对比" :visible.sync="compareDialogVisible" width="90%">
        <el-radio-group v-model="compareModel" @change="onChange">
          <el-radio-button label=0>养成分统计</el-radio-button>
          <el-radio-button v-if="searchForm.dimension !== 'individual'" label=1>养成分数统计</el-radio-button>
          <el-radio-button v-if="searchForm.dimension !== 'individual'" label=2>记录次数统计</el-radio-button>
        </el-radio-group>
        <div style="height: 300px; padding-top: 20px;"> <!-- Add padding-top -->
          <div ref="studentCompareChart" style="width: 100%; height: 100%;"></div>
        </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="compareDialogVisible = false">关闭</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";  // 假设你有这个实例配置
import api from "../../api/index";
import { fetchCategories, fetchClasses, fetchGrades } from "../../api/api_util";
import * as xlsx from "xlsx";  // 假设你有这个API配置
import * as echarts from "echarts";
import { provinces, airlines } from '../../util/constants.js';

export default {
  data() {
    return {
      provinces,
      airlines,
      searchForm: {
        dimension: '',
        queryType: '',
        queryValue: '',
        timeDimension: 'total',
        category: '',
        sub_category: '',
        startDate: '',
        endDate: '',
        queryClassIds: [],
        queryGradeIds: [],
        selectedTerms: [],
        provinces: [],
        airline_names: [],
      },
      detailTableData: [],
      detailDialogVisible: false,
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      grades: [],
      classes: [],
      categories: [],
      categories_cascader: [],
      detail_start: '',
      detail_end: '',
      detailCurrentPage: 1,
      detailPageSize: 10,
      detailTotal: 0,
      detailSortOrder: null, // 排序顺序
      detailSortField: null, // 排序字段
      excelLoading: false,
      searchLoading: false,
      sortField: null,
      sortOrder: null,
      tableColumns: [],
      selectedRecords: [],
      compareDialogVisible: false,
      compareModel: 0,
      semesterList: [],
    };
  },
  watch: {
    'searchForm.dimension'(newVal) {
      this.setTableColumns(newVal);
    }
  },
  methods: {
    handleDetailClose() {
      this.detailDialogVisible = false;
      this.detailTableData = [];
      this.detailCurrentPage = 1;
      this.detailTotal = 0;
    },
    onChange() {
      this.compareSelected();
    },
    sortDevName(str1, str2) {
      const numA = parseFloat(str1.total_deduction_score) || 0;
      const numB = parseFloat(str2.total_deduction_score) || 0;
      return numA - numB;
    },
    setTableColumns(dimension) {
      this.tableData = [];
      if (dimension === 'individual') {
        this.tableColumns = [
          { prop: 'student_number', label: '学号', width: '100', sortable: true },
          { prop: 'name', label: '姓名', width: '80', sortable: false },
          { prop: 'grade_id', label: '年级', width: '70', sortable: false },
          { prop: 'class_id', label: '班级', width: '70', sortable: false },
          { prop: 'total_deduction_score', label: '扣分分数', width: '120', sortable: true },
          { prop: 'deduction_count', label: '扣分次数', width: '120', sortable: true },
          { prop: 'total_addition_score', label: '加分分数', width: '120', sortable: true },
          { prop: 'addition_count', label: '加分次数', width: '120', sortable: true },
          { prop: 'search_range', label: '时间范围', width: '200', sortable: true }
        ];
      } else if (dimension === 'class' || dimension === 'grade') {
        this.tableColumns = [
          { prop: 'class_id', label: '班级', width: '100', sortable: true },
          { prop: 'total_deduction_score', label: '班级扣分分数', width: '150', sortable: true },
          { prop: 'deduction_count', label: '班级扣分次数', width: '150', sortable: true },
          { prop: 'total_addition_score', label: '班级加分分数', width: '150', sortable: true },
          { prop: 'addition_count', label: '班级加分次数', width: '150', sortable: true },
          { prop: 'search_range', label: '时间范围', width: '200', sortable: true }
        ];
      }
    },
    handleDetailSortChange({ prop, order }) {
      if (this.searchForm.dimension === 'individual') {
        this.detailSortField = prop;
        this.detailSortOrder = order === 'ascending' ? 'asc' : 'desc';
        this.viewDetails({ student_number: this.detailTableData[0].student_number });
      }
    },
    handleDimensionChange() {
      this.searchForm.queryValue = '';
      this.searchForm.queryType = '';
      this.sortField = null;
      this.sortOrder = null;
    },
    handleTypeChange(val) {
      console.log(val);
      const checkedNodes = this.$refs['search_cascader'].getCheckedNodes();
      if (checkedNodes.length === 0) {
        this.searchForm.category = '';
        this.searchForm.sub_category = '';
        return;
      }
      if (checkedNodes[0].pathLabels.length > 0) {
        this.searchForm.category = checkedNodes[0].pathLabels[0];
        if (checkedNodes[0].pathLabels.length > 1) {
          this.searchForm.sub_category = checkedNodes[0].pathLabels[1];
        }
      }
      console.log(this.searchForm);
    },
    async fetchClassesAndGrades() {
      try {
        this.classes = await fetchClasses();
        this.grades = await fetchGrades();
        this.categories_cascader = await fetchCategories();
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    handleSearch() {
      // 将查询表单数据转换为请求参数
      if (this.searchForm.dimension.length === 0) {
        this.$message.warning("请选择查询范围");
        return;
      }
      if (this.searchForm.queryType.length > 0 && this.searchForm.queryValue.length === 0) {
        this.$message.warning("请输入查询值");
        return
      }
      if (this.searchForm.dimension === 'grade' && this.searchForm.queryGradeIds.length === 0) {
        this.$message.warning("请选择查询年级，无权限请联系管理员开通");
        return;
      }
      if (this.searchForm.dimension === 'class' && this.searchForm.queryClassIds.length === 0) {
        this.$message.warning("请选择查询班级，无权限请联系管理员开通");
        return;
      }
      if (this.searchForm.dimension === 'term' && this.searchForm.selectedTerms.length === 0) {
        this.$message.warning("请选择学期");
        return;
      }
      const params = {
        dimension: this.searchForm.dimension,
        query_type: this.searchForm.queryType,
        query_value: this.searchForm.queryValue,
        time_dimension: this.searchForm.timeDimension,
        category: this.searchForm.category,
        sub_category: this.searchForm.sub_category,
        start_date: this.searchForm.startDate,
        end_date: this.searchForm.endDate,
        provinces: this.searchForm.provinces,
        airline_names: this.searchForm.airline_names,
        page: this.currentPage,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder,
        selected_terms: this.searchForm.selectedTerms,
      };

      let apiEndpoint;


      if (this.searchForm.dimension === 'individual') {
        apiEndpoint = api.queryStudentDevelopSocres;
      } else if (this.searchForm.dimension === 'class' || this.searchForm.dimension === 'grade') {
        apiEndpoint = api.queryClass;
        // 根据不同的维度增加额外参数
        if (this.searchForm.dimension === 'class') {
          params.class_ids = this.searchForm.queryClassIds;
        } else if (this.searchForm.dimension === 'grade') {
          params.grade_ids = this.searchForm.queryGradeIds;
        }
      }


      // 发送 GET 请求到后端 API
      axiosInstance.get(apiEndpoint, { params })
        .then(response => {
          if (response.data.status === 'success') {
            // 更新表格数据和总数
            this.tableData = response.data.data.scores;
            this.total = response.data.data.total;
            this.$message.success("查询成功");
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error('获取数据失败：' + error);
        });
    },
    handlePageChange(page) {
      // 更新当前页数并重新发起查询
      this.currentPage = page;
      this.handleSearch();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1; // 可选：在修改 pageSize 后，重置为第一页
      this.handleSearch();
    },
    handleSortChange({ prop, order }) {
      // 更新排序字段和顺序并重新发起查询
      this.sortField = prop;
      this.sortOrder = order === 'ascending' ? 'asc' : 'desc';
      if (this.searchForm.dimension == 'individual') {
        this.handleSearchWithSorting();
      } else {
        this.handleSearch();
      }
    },
    handleSearchWithSorting() {
      // 将查询表单数据转换为请求参数
      if (this.searchForm.dimension.length === 0) {
        this.$message.warning("请选择查询范围");
        return;
      }
      if (this.searchForm.queryType.length > 0 && this.searchForm.queryValue.length === 0) {
        this.$message.warning("请输入查询值");
        return
      }
      if (this.searchForm.dimension === 'grade' && this.searchForm.queryGradeIds.length === 0) {
        this.$message.warning("请选择查询年级，无权限请联系管理员开通");
        return;
      }
      if (this.searchForm.dimension === 'class' && this.searchForm.queryClassIds.length === 0) {
        this.$message.warning("请选择查询班级，无权限请联系管理员开通");
        return;
      }
      if (this.searchForm.dimension === 'term' && this.searchForm.selectedTerms.length === 0) {
        this.$message.warning("请选择学期");
        return;
      }
      this.searchLoading = true;
      // 将排序字段和顺序添加到请求参数中并发送请求
      const params = {
        dimension: this.searchForm.dimension,
        query_type: this.searchForm.queryType,
        query_value: this.searchForm.queryValue,
        time_dimension: this.searchForm.timeDimension,
        category: this.searchForm.category,
        sub_category: this.searchForm.sub_category,
        start_date: this.searchForm.startDate,
        end_date: this.searchForm.endDate,
        page: this.currentPage,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder,
        selected_terms: this.searchForm.selectedTerms,
      };

      axiosInstance.get(api.queryStudentDevelopSocres, { params })
        .then(response => {
          if (response.data.status === 'success') {
            // 更新表格数据和总数
            const scores = response.data.data.scores;
            this.tableData = scores;
            this.total = response.data.data.total;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch(error => {
          this.$message.error('获取数据失败：' + error);
        });
      this.searchLoading = false;
    },
    viewDetails(row) {
      if (this.searchForm.dimension === 'individual') {
        // 即使时间范围不为空，也要重新获取该行的数据，放置时间范围锁死
        if (row.term_range) {
          const [start, end] = row.term_range.split(':');
          this.detail_start = start;
          this.detail_end = end;
        } else if (row.search_range) {
          const [start, end] = row.search_range.split(':');
          this.detail_start = start;
          this.detail_end = end;
        } else {
          this.detail_start = this.searchForm.startDate;
          this.detail_end = this.searchForm.endDate;
        }

        // Get detailed information of the student
        const params = {
          student_number: row.student_number,
          start_date: this.detail_start,
          end_date: this.detail_end,
          category: this.searchForm.category,
          sub_category: this.searchForm.sub_category,
          page: this.detailCurrentPage,
          page_size: this.detailPageSize,
          sort_field: this.detailSortField,
          sort_order: this.detailSortOrder
        };
        axiosInstance.get(api.queryDevelopmentScores, { params })
          .then(response => {
            if (response.data.status === 'success') {
              this.detailTableData = response.data.data.scores;
              this.detailTotal = response.data.data.total;
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch(error => {
            this.$message.error('获取学生详细信息失败：' + error);
          });
      } else {
        this.detailTableData = row.detail_category_info;
        this.detailTotal = this.detailTableData.length;
      }
      this.detailDialogVisible = true;
    },
    handleDetailPageChange(page) {
      this.detailCurrentPage = page;
      this.viewDetails({ student_number: this.detailTableData[0].student_number });
    },


    compareSelected() {
      this.selectedRecords = this.$refs.result_table.selection;
      console.log(JSON.stringify(this.selectedRecords, null, 2));
      if (this.selectedRecords.length < 2) {
        this.$message.warning("请至少选择两条记录进行对比");
        return;
      }
      if (this.searchForm.dimension === 'individual') {
        const studentNumbers = this.selectedRecords.map(record => record.student_number);
        const uniqueIds = [...new Set(studentNumbers)];

        if (uniqueIds.length > 1) {
          this.$message.warning("请选择同一学生进行可视化");
          return;
        }
      } else {
        const classIds = this.selectedRecords.map(record => record.class_id);
        const uniqueClassIds = [...new Set(classIds)];

        if (uniqueClassIds.length > 1) {
          this.$message.warning("请选择同一班级进行可视化");
          return;
        }
      }
      this.compareDialogVisible = true;
      this.$nextTick(() => {
        if (this.searchForm.dimension === 'individual') {
          this.generateStudentCompareChart();
        } else {
          if (this.compareModel == 0) {
            this.generateClassTimeSeriesChart();
          } else {
            this.generateClassCompareChart();
          }
        }
      });
    },
    generateClassCompareChart() {
      // Aggregate scores by category
      let categoryAggregation = {}
      if (this.compareModel == 1) {
        categoryAggregation = this.selectedRecords.reduce((acc, record) => {
          record.detail_category_info.forEach(detail => {
            if (!acc[detail.category]) {
              acc[detail.category] = {
                total_addition_score: 0,
                total_deduction_score: 0
              };
            }
            acc[detail.category].total_addition_score += Math.abs(parseFloat(detail.total_addition_score));
            acc[detail.category].total_deduction_score += Math.abs(parseFloat(detail.total_deduction_score));
          });
          return acc;
        }, {});
      } else {
        categoryAggregation = this.selectedRecords.reduce((acc, record) => {
          record.detail_category_info.forEach(detail => {
            if (!acc[detail.category]) {
              acc[detail.category] = {
                total_addition_score: 0,
                total_deduction_score: 0
              };
            }
            acc[detail.category].total_addition_score += detail.addition_count;
            acc[detail.category].total_deduction_score += detail.deduction_count;
          });
          return acc;
        }, {});
      }

      // Prepare data for pie chart
      const additionData = Object.keys(categoryAggregation).map(category => ({
        name: category,
        value: categoryAggregation[category].total_addition_score
      }));

      const deductionData = Object.keys(categoryAggregation).map(category => ({
        name: category,
        value: categoryAggregation[category].total_deduction_score
      }));

      // Option for pie chart
      const option = {
        title: {
          text: '按类别的加减分数统计',
          subtext: '加分                                       扣分',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: Object.keys(categoryAggregation)
        },
        series: [
          {
            name: '加分记录',
            type: 'pie',
            radius: '50%',
            center: ['25%', '50%'],
            data: additionData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          },
          {
            name: '扣分记录',
            type: 'pie',
            radius: '50%',
            center: ['75%', '50%'],
            data: deductionData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      let chart;
      if (echarts.getInstanceByDom(this.$refs.studentCompareChart)) {
        // 如果存在，则直接获取该实例
        chart = echarts.getInstanceByDom(this.$refs.studentCompareChart);
        chart.clear();
      } else {
        // 如果不存在，则初始化一个新实例
        chart = echarts.init(this.$refs.studentCompareChart);
      }
      chart.setOption(option);
    },
    generateClassTimeSeriesChart() {
      this.selectedRecords.sort((a, b) => new Date(a.search_range.split(':')[0]) - new Date(b.search_range.split(':')[0]));
      const categories = this.selectedRecords.map(record => record.search_range);
      const totalDeductionScores = this.selectedRecords.map(record => Math.abs(parseFloat(record.total_deduction_score)));
      const totalAdditionScores = this.selectedRecords.map(record => Math.abs(parseFloat(record.total_addition_score)));
      const deductionCounts = this.selectedRecords.map(record => record.deduction_count);
      const additionCounts = this.selectedRecords.map(record => record.addition_count);

      const option = {
        title: {
          text: '加减分对比'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['扣分分数', '加分分数', '扣分次数', '加分次数']
        },
        xAxis: {
          type: 'category',
          data: categories
        },
        yAxis: [
          {
            type: 'value',
            name: '分数',
            position: 'left'
          },
          {
            type: 'value',
            name: '次数',
            position: 'right'
          }
        ],
        series: [
          {
            name: '扣分分数',
            type: 'bar',
            data: totalDeductionScores,
            yAxisIndex: 0
          },
          {
            name: '加分分数',
            type: 'bar',
            data: totalAdditionScores,
            yAxisIndex: 0
          },
          {
            name: '扣分次数',
            type: 'line',
            data: deductionCounts,
            yAxisIndex: 1
          },
          {
            name: '加分次数',
            type: 'line',
            data: additionCounts,
            yAxisIndex: 1
          }
        ]
      };

      let chart;
      if (echarts.getInstanceByDom(this.$refs.studentCompareChart)) {
        // 如果存在，则直接获取该实例
        chart = echarts.getInstanceByDom(this.$refs.studentCompareChart);
        chart.clear();
      } else {
        // 如果不存在，则初始化一个新实例
        chart = echarts.init(this.$refs.studentCompareChart);
      }
      chart.setOption(option);
    },
    generateStudentCompareChart() {
      // Sort selectedRecords by search_range
      this.selectedRecords.sort((a, b) => new Date(a.search_range.split(':')[0]) - new Date(b.search_range.split(':')[0]));
      const categories = this.selectedRecords.map(record => record.search_range);
      const totalDeductionScores = this.selectedRecords.map(record => Math.abs(parseFloat(record.total_deduction_score)));
      const totalAdditionScores = this.selectedRecords.map(record => Math.abs(parseFloat(record.total_addition_score)));
      const deductionCounts = this.selectedRecords.map(record => record.deduction_count);
      const additionCounts = this.selectedRecords.map(record => record.addition_count);

      const studentNumber = this.selectedRecords[0].student_number;
      const studentName = this.selectedRecords[0].name;

      let chartTitle = `${studentNumber} ${studentName} `;
      const option = {
        title: {
          text: chartTitle + '加减分对比'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['扣分分数', '加分分数', '扣分次数', '加分次数']
        },
        xAxis: {
          type: 'category',
          data: categories
        },
        yAxis: [
          {
            type: 'value',
            name: '分数',
            position: 'left'
          },
          {
            type: 'value',
            name: '次数',
            position: 'right'
          }
        ],
        series: [
          {
            name: '扣分分数',
            type: 'bar',
            data: totalDeductionScores,
            yAxisIndex: 0
          },
          {
            name: '加分分数',
            type: 'bar',
            data: totalAdditionScores,
            yAxisIndex: 0
          },
          {
            name: '扣分次数',
            type: 'line',
            data: deductionCounts,
            yAxisIndex: 1
          },
          {
            name: '加分次数',
            type: 'line',
            data: additionCounts,
            yAxisIndex: 1
          }
        ]
      };
      let chart;
      if (echarts.getInstanceByDom(this.$refs.studentCompareChart)) {
        // 如果存在，则直接获取该实例
        chart = echarts.getInstanceByDom(this.$refs.studentCompareChart);
        chart.clear();
      } else {
        // 如果不存在，则初始化一个新实例
        chart = echarts.init(this.$refs.studentCompareChart);
      }
      chart.setOption(option);
    },

    //-----------------------------------数据导出
    async fetchAllData(params, apiEndPoint) {
      let allData = [];
      let currentPage = 1;
      let totalPages = 1;
      do {
        params.page = currentPage;
        const response = await axiosInstance.get(apiEndPoint, { params });
        const resData = response.data.data;
        allData.push(...resData.scores);
        currentPage += 1;
        totalPages = resData.total / params.page_size;
        if (resData.total % params.page_size > 0) {
          totalPages += 1;
        }
      } while (currentPage <= totalPages);
      console.log("allData", allData);
      return allData;
    },

    async getData() {
      const params = {
        dimension: this.searchForm.dimension,
        query_type: this.searchForm.queryType,
        query_value: this.searchForm.queryValue,
        time_dimension: this.searchForm.timeDimension,
        category: this.searchForm.category,
        sub_category: this.searchForm.sub_category,
        start_date: this.searchForm.startDate,
        end_date: this.searchForm.endDate,
        provinces: this.searchForm.provinces,
        airline_names: this.searchForm.airline_names,
        page_size: this.pageSize,
        sort_field: this.sortField,
        sort_order: this.sortOrder,
        selected_terms: this.searchForm.selectedTerms,
      };

      let apiEndpoint;
      if (this.searchForm.dimension === 'individual') {
        apiEndpoint = api.queryStudentDevelopSocres;
      } else if (this.searchForm.dimension === 'class' || this.searchForm.dimension === 'grade') {
        apiEndpoint = api.queryClass;
        // 根据不同的维度增加额外参数
        if (this.searchForm.dimension === 'class') {
          params.class_ids = this.searchForm.queryClassIds;
        } else if (this.searchForm.dimension === 'grade') {
          params.grade_ids = this.searchForm.queryGradeIds;
        }
      }
      return await this.fetchAllData(params, apiEndpoint);
    },

    generateRowsToExcel(data) {
      if (this.searchForm.dimension == 'individual') {
        return data.map(item => ({
          学号: item.student_number,
          姓名: item.name,
          班级: item.class_id,
          年级: item.grade_id,
          总扣分数: item.total_deduction_score,
          总扣分次数: item.deduction_count,
          总加分数: item.total_addition_score,
          总加分次数: item.addition_count,
          统计时间: item.search_range,
        }));
      } else {
        return data.map(item => ({
          班级: item.class_id,
          班级扣分数: item.total_deduction_score,
          班级扣分次数: item.deduction_count,
          班级加分数: item.total_addition_score,
          班级加分次数: item.addition_count,
          统计时间: item.search_range,
        }));
      }
    },

    async exportToExcel() {
      // 将查询表单数据转换为请求参数
      if (this.searchForm.dimension.length === 0) {
        this.$message.warning("请选择查询范围");
        return;
      }
      if (this.searchForm.queryType.length > 0 && this.searchForm.queryValue.length === 0) {
        this.$message.warning("请输入查询值");
        return
      }
      if (this.searchForm.dimension === 'grade' && this.searchForm.queryGradeIds.length === 0) {
        this.$message.warning("请选择查询年级，无权限请联系管理员开通");
        return;
      }
      if (this.searchForm.dimension === 'class' && this.searchForm.queryClassIds.length === 0) {
        this.$message.warning("请选择查询班级，无权限请联系管理员开通");
        return;
      }
      this.excelLoading = true;
      try {
        const data = await this.getData();
        const rows = this.generateRowsToExcel(data);
        console.log(rows);
        const ws = xlsx.utils.json_to_sheet(rows);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Report");
        xlsx.writeFile(wb, "report.xlsx");
      } catch (error) {
        console.error("Error exporting to Excel:", error);
      } finally {
        this.excelLoading = false;
      }
    },
    fetchTermList() {
      axiosInstance.get(api.listSemesters)
        .then(response => {
          this.semesterList = response.data.data;
        })
        .catch(error => {
          this.$message.error('获取学期列表失败: ' + error);
        });
    },
    formatStatus(status) {
      const statusMap = {
        0: '生效中',
        1: '已作废',
      };
      return statusMap[status];
    },
    getStatusType(status) {
      const typeMap = {
        0: 'success',
        1: 'info',
      };
      return typeMap[status];
    },
  },
  async mounted() {
    const token = localStorage.getItem('token');
    if (token == null || token.length === 0) {
      this.$message.error('请先登录');
      this.$router.push({ path: "/login" });
      return;
    }
    this.fetchTermList();
    await this.fetchClassesAndGrades();
  }
}
</script>

<style scoped>
.el-form-item {
  margin-right: 20px;
}

.card-title {
  font-size: 1rem;
  /* Adjust this to your preferred size */
  margin-bottom: 0.1rem;
}

.f-width {
  width: 220px;
}

.f-width-a {
  width: 100% !important;
}

.h-width {
  width: 105px !important;
}


.pagination-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  /* 保持内容不换行 */
  -ms-overflow-style: none;
  /* 针对 IE 和 Edge 隐藏滚动条 */
  scrollbar-width: none;
  /* 针对 Firefox 隐藏滚动条 */
}

.pagination-wrapper::-webkit-scrollbar {
  display: none;
  /* 针对 Chrome、Safari 隐藏滚动条 */
}

.el-pagination {
  display: inline-block;
  /* 使分页组件在一行内 */
}
</style>