<template>
  <div class="mb-2">
    <el-card class="card">
      <div class="col-md-12">
        <div slot="header" class="clearfix">
          <h3 class="card-title">成绩纵向对比分析</h3>
        </div>
        
        <!-- 比较项目列表 -->
        <div class="compare-items">
          <div v-for="(item, index) in analysisForm.compare_items" :key="index" class="compare-item">
            <el-card class="item-card">
              <div slot="header" class="item-header">
                <span>对比项 {{ index + 1 }}</span>
                <el-button 
                  type="danger" 
                  size="mini" 
                  icon="el-icon-delete"
                  @click="removeCompareItem(index)"
                  v-if="analysisForm.compare_items.length > 1">
                  删除
                </el-button>
              </div>
              
              <el-form :inline="true" :model="item" class="item-form">
                <!-- 学期选择 -->
                <el-form-item label="学期">
                  <el-select 
                    v-model="item.semester_id" 
                    placeholder="请选择学期"
                    @change="() => handleSemesterChange(index)"
                    clearable>
                    <el-option
                      v-for="semester in semesters"
                      :key="semester.id"
                      :label="semester.semester_name"
                      :value="semester.id">
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- 课程选择 -->
                <el-form-item label="课程">
                  <el-select 
                    v-model="item.course_id"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入课程名称搜索"
                    :remote-method="(query) => searchCourses(query, index)"
                    :loading="item.courseLoading"
                    clearable>
                    <el-option
                      v-for="course in item.courseOptions"
                      :key="course.value"
                      :label="course.label"
                      :value="course.value">
                      <span>{{ formatCourseName(course.label) }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">
                        {{ formatCourseCode(course.label) }}
                      </span>
                    </el-option>
                  </el-select>
                </el-form-item>

                <!-- 年级选择 -->
                <el-form-item label="年级">
                  <el-select 
                    v-model="item.grade_ids" 
                    multiple 
                    collapse-tags
                    placeholder="请选择年级" 
                    clearable>
                    <el-option
                      v-for="grade in grades"
                      :key="grade"
                      :label="grade"
                      :value="grade">
                    </el-option>
                  </el-select>
                </el-form-item>
                
                <!-- 班级选择 -->
                <el-form-item label="班级">
                  <el-select 
                    v-model="item.class_ids" 
                    multiple 
                    collapse-tags
                    placeholder="请选择班级" 
                    clearable>
                    <el-option
                      v-for="classItem in classes"
                      :key="classItem"
                      :label="classItem"
                      :value="classItem">
                    </el-option>
                  </el-select>
                </el-form-item>
                
                <!-- 航司选择 -->
                <el-form-item label="航司">
                  <el-select 
                    v-model="item.airline_names" 
                    multiple 
                    collapse-tags
                    placeholder="请选择航司" 
                    clearable>
                    <el-option
                      v-for="airline in airlines"
                      :key="airline"
                      :label="airline"
                      :value="airline">
                    </el-option>
                  </el-select>
                </el-form-item>
                
                <!-- 省份选择 -->
                <el-form-item label="省份">
                  <el-select 
                    v-model="item.province_names" 
                    multiple 
                    collapse-tags
                    placeholder="请选择省份" 
                    clearable>
                    <el-option
                      v-for="province in provinces"
                      :key="province"
                      :label="province"
                      :value="province">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </el-card>
          </div>

          <!-- 添加比较项按钮 -->
          <el-button 
            type="dashed" 
            class="add-item-btn"
            @click="addCompareItem"
            icon="el-icon-plus">
            添加对比项
          </el-button>
        </div>

        <!-- 分析按钮 -->
        <div class="analysis-actions">
          <el-button type="primary" @click="handleAnalysis" :loading="loading">开始分析</el-button>
          <el-button @click="resetAnalysis">重置</el-button>
        </div>

        <!-- 分析结果表格 -->
        <el-table 
          v-if="analysisData.length > 0"
          :data="analysisData" 
          border 
          @sort-change="handleTableSort"
          style="width: 100%; margin-top: 20px;">
          <el-table-column prop="composite_name" label="集体名称" width="250" sortable="custom"></el-table-column>
          <el-table-column prop="student_count" label="人数" width="80" sortable="custom">
            <template slot-scope="scope">
              <el-tag type="info">{{ scope.row.student_count }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="avg_score" label="平均分" width="100" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.avg_score.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="median_score" label="中位数" width="100" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.median_score.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="max_score" label="最高分" width="100" sortable="custom"></el-table-column>
          <el-table-column prop="min_score" label="最低分" width="100" sortable="custom"></el-table-column>
          <el-table-column prop="excellent_rate" label="优秀率" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.excellent_rate.toFixed(2) }}%
            </template>
          </el-table-column>
        </el-table>

        <!-- 图表展示 -->
        <div v-if="analysisData.length > 0" class="charts-container">
          <!-- 柱状图 -->
          <div class="chart-item">
            <div class="chart-header">
              <h4>成绩分布柱状图</h4>
              <el-button size="small" @click="exportChart('bar')">导出图片</el-button>
            </div>
            <div ref="barChart" style="width: 100%; height: 400px;"></div>
          </div>
          <!-- 折线图 -->
          <div class="chart-item">
            <div class="chart-header">
              <h4>优秀率趋势图</h4>
              <el-button size="small" @click="exportChart('line')">导出图片</el-button>
            </div>
            <div ref="lineChart" style="width: 100%; height: 400px;"></div>
          </div>
        </div>

        <!-- 导出按钮 -->
        <div class="export-container" v-if="analysisData.length > 0">
          <el-button-group>
            <el-button type="primary" @click="exportToExcel" :loading="exportLoading">
              导出数据
            </el-button>
            <el-button type="success" @click="exportAllCharts" :loading="chartExporting">
              导出所有图表
            </el-button>
          </el-button-group>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api/index";
import * as echarts from 'echarts';
import { provinces, airlines } from '../../util/constants';

export default {
  name: 'VerticalAnalysis',
  data() {
    return {
      analysisForm: {
        compare_items: [{
          semester_id: '',
          course_id: '',
          grade_ids: [],
          class_ids: [],
          airline_names: [],
          province_names: [],
          courseOptions: [],
          courseLoading: false
        }]
      },
      semesters: [],
      grades: [],
      classes: [],
      airlines: airlines,
      provinces: provinces,
      loading: false,
      exportLoading: false,
      chartExporting: false,
      analysisData: [],
      barChart: null,
      lineChart: null
    }
  },
  computed: {
    compareTypeLabel() {
      const labels = {
        class: '班级',
        grade: '年级',
        airline: '航空公司',
        province: '省份'
      };
      return labels[this.analysisForm.compare_type] || '比较项目';
    },
    compareOptions() {
      const type = this.analysisForm.compare_type;
      if (type === 'class') return this.classes;
      if (type === 'grade') return this.grades;
      if (type === 'airline') return airlines;
      if (type === 'province') return provinces;
      return [];
    }
  },
  methods: {
    // 获取基础数据
    async fetchBaseData() {
      try {
        // 获取所有学期
        const semesterRes = await axiosInstance.get(api.listSemesters);
        if (semesterRes.data.status === 'success') {
          this.semesters = semesterRes.data.data.sort((a, b) => b.id - a.id);
        } else {
          this.$message.error(semesterRes.data.message || '获取学期列表失败');
          return;
        }

        // 获取所有班级
        const classRes = await axiosInstance.get(api.getClassInfo);
        if (classRes.data.status === 'success') {
          this.classes = classRes.data.data.sort();
        } else {
          this.$message.error(classRes.data.message || '获取班级列表失败');
          return;
        }

        // 获取所有年级
        const gradeRes = await axiosInstance.get(api.getGradeInfo);
        if (gradeRes.data.status === 'success') {
          this.grades = gradeRes.data.data.sort();
        } else {
          this.$message.error(gradeRes.data.message || '获取年级列表失败');
          return;
        }

        console.log('Base data loaded:', {
          semesters: this.semesters,
          classes: this.classes,
          grades: this.grades
        });

      } catch (error) {
        console.error('Error in fetchBaseData:', error);
        this.$message.error('获取基础数据失败');
      }
    },

    // 搜索课程
    async searchCourses(query, index) {
      const item = this.analysisForm.compare_items[index];
      if (!item.semester_id) {
        this.$message.warning('请先选择学期');
        return;
      }
      
      item.courseLoading = true;
      try {
        const response = await axiosInstance.get(
          api.getSemesterCourses(item.semester_id)
        );
        if (response.data.status === 'success') {
          item.courseOptions = response.data.data.filter(course => 
            course.label.toLowerCase().includes(query.toLowerCase())
          );
          console.log('Filtered courses:', item.courseOptions);
        }
      } catch (error) {
        console.error('Error in searchCourses:', error);
        this.$message.error('获取课程列表失败');
      } finally {
        item.courseLoading = false;
      }
    },

    // 处理学期变化
    handleSemesterChange(index) {
      const item = this.analysisForm.compare_items[index];
      item.course_id = ''; // 清空课程选择
      item.courseOptions = []; // 清空课程选项
      // 如果选择了学期，自动触发课程搜索
      if (item.semester_id) {
        this.searchCourses('', index);
      }
    },

    // 添加比较项
    addCompareItem() {
      this.analysisForm.compare_items.push({
        semester_id: '',
        course_id: '',
        grade_ids: [],
        class_ids: [],
        airline_names: [],
        province_names: [],
        courseOptions: [],
        courseLoading: false
      });
    },

    // 删除比较项
    removeCompareItem(index) {
      this.analysisForm.compare_items.splice(index, 1);
    },

    // 格式化课程名称
    formatCourseName(label) {
      return label.split(' (')[0];
    },
    
    // 格式化课程代码
    formatCourseCode(label) {
      const match = label.match(/\((.*?)\)/);
      return match ? match[1] : '';
    },

    // 执行分析
    async handleAnalysis() {
      this.loading = true;
      try {
        const response = await axiosInstance.post(api.analyzeVertical, {
          compare_items: this.analysisForm.compare_items
        });

        if (response.data.status === 'success') {
          this.analysisData = response.data.data;
          this.$nextTick(() => {
            this.initBarChart();
            this.initLineChart();
          });
          this.$message.success('分析完成');
        } else {
          this.$message.error(response.data.message || '分析失败');
        }
      } catch (error) {
        console.error('Analysis error:', error);
        this.$message.error('分析请求失败');
      } finally {
        this.loading = false;
      }
    },

    // 重置分析
    resetAnalysis() {
      this.analysisForm = {
        compare_items: [{
          semester_id: '',
          course_id: '',
          grade_ids: [],
          class_ids: [],
          airline_names: [],
          province_names: [],
          courseOptions: [],
          courseLoading: false
        }]
      };
      this.analysisData = [];
      if (this.barChart) this.barChart.dispose();
      if (this.lineChart) this.lineChart.dispose();
      this.barChart = null;
      this.lineChart = null;
    },

    // 初始化柱状图
    initBarChart() {
      if (this.barChart) this.barChart.dispose();
      this.barChart = echarts.init(this.$refs.barChart);

      const option = {
        title: { text: '' },
        tooltip: { trigger: 'axis' },
        legend: {
          data: ['平均分', '中位数', '最高分', '最低分'],
          bottom: 0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.analysisData.map(item => item.composite_name),
          axisLabel: {
            interval: 0,
            rotate: 30
          }
        },
        yAxis: {
          type: 'value',
          name: '分数'
        },
        series: [
          {
            name: '平均分',
            type: 'bar',
            data: this.analysisData.map(item => item.avg_score)
          },
          {
            name: '中位数',
            type: 'bar',
            data: this.analysisData.map(item => item.median_score)
          },
          {
            name: '最高分',
            type: 'bar',
            data: this.analysisData.map(item => item.max_score)
          },
          {
            name: '最低分',
            type: 'bar',
            data: this.analysisData.map(item => item.min_score)
          }
        ]
      };

      this.barChart.setOption(option);
    },

    // 初始化折线图
    initLineChart() {
      if (this.lineChart) this.lineChart.dispose();
      this.lineChart = echarts.init(this.$refs.lineChart);

      const option = {
        title: { text: '' },
        tooltip: { trigger: 'axis' },
        legend: {
          data: ['优秀率'],
          bottom: 0
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.analysisData.map(item => item.composite_name),
          axisLabel: {
            interval: 0,
            rotate: 30
          }
        },
        yAxis: {
          type: 'value',
          name: '百分比',
          axisLabel: {
            formatter: '{value}%'
          }
        },
        series: [
          {
            name: '优秀率',
            type: 'line',
            data: this.analysisData.map(item => item.excellent_rate)
          }
        ]
      };

      this.lineChart.setOption(option);
    },

    // 导出到Excel
    async exportToExcel() {
      this.exportLoading = true;
      try {
        // 准备CSV数据
        const headers = ['集体名称', '人数', '平均分', '中位数', '最高分', '最低分', '优秀率'];
        const rows = this.analysisData.map(item => [
          item.composite_name,
          item.student_count,
          Number(item.avg_score).toFixed(2),
          Number(item.median_score).toFixed(2),
          item.max_score,
          item.min_score,
          Number(item.excellent_rate).toFixed(2) + '%'
        ]);

        // 创建CSV内容
        const csvContent = [
          headers.join(','),
          ...rows.map(row => row.join(','))
        ].join('\n');

        // 创建Blob对象
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
        // 创建下载链接
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `纵向对比分析_${new Date().toISOString().split('T')[0]}.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        this.$message.success('导出成功');
      } catch (error) {
        console.error('Export error:', error);
        this.$message.error('导出失败: ' + error.message);
      } finally {
        this.exportLoading = false;
      }
    },

    // 导出图表
    exportChart(type) {
      const chart = type === 'bar' ? this.barChart : this.lineChart;
      const title = type === 'bar' ? '成绩分布' : '优秀率趋势';
      
      try {
        const url = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
        });
        
        const link = document.createElement('a');
        link.download = `纵向对比_${title}_${new Date().toISOString().split('T')[0]}.png`;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        this.$message.success('图表导出成功');
      } catch (error) {
        console.error('Chart export error:', error);
        this.$message.error('图表导出失败');
      }
    },

    // 导出所有图表
    async exportAllCharts() {
      this.chartExporting = true;
      try {
        await this.exportChart('bar');
        await this.exportChart('line');
      } finally {
        this.chartExporting = false;
      }
    },

    // 处理表格排序
    handleTableSort({ prop, order }) {
      const data = [...this.analysisData];
      data.sort((a, b) => {
        if (order === 'ascending') {
          return a[prop] - b[prop];
        } else if (order === 'descending') {
          return b[prop] - a[prop];
        }
        return 0;
      });
      this.analysisData = data;
    },
  },
  mounted() {
    this.fetchBaseData();
  },
  beforeDestroy() {
    if (this.barChart) this.barChart.dispose();
    if (this.lineChart) this.lineChart.dispose();
  }
}
</script>

<style scoped>
.compare-items {
  margin: 20px 0;
}

.compare-item {
  margin-bottom: 20px;
}

.item-card {
  margin-bottom: 15px;
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-item-btn {
  width: 100%;
  border-style: dashed;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chart-header h4 {
  margin: 0;
  font-size: 16px;
  color: #303133;
}

.charts-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.chart-item {
  flex: 1;
  min-width: 300px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  padding: 20px;
}

.el-select {
  width: 200px;
}

.export-container {
  margin-top: 20px;
  text-align: right;
}

.analysis-actions {
  margin-top: 20px;
  text-align: right;
}
</style> 