<template>
  <div class="mb-2">
    <el-card class="card">
      <div class="col-md-12">
        <div slot="header" class="clearfix">
          <h3 class="card-title">成绩对比分析</h3>
        </div>
        
        <!-- 分析条件表单 -->
        <el-form :inline="true" :model="analysisForm" class="analysis-form">
          <!-- 学期选择 -->
          <el-form-item label="学期">
            <el-select 
              v-model="analysisForm.semester_id" 
              placeholder="请选择学期" 
              @change="handleSemesterChange"
              clearable>
              <el-option
                v-for="semester in semesters"
                :key="semester.id"
                :label="semester.semester_name"
                :value="semester.id">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 课程选择 -->
          <el-form-item label="课程">
            <el-select 
              v-model="analysisForm.course_id"
              filterable
              remote
              reserve-keyword
              placeholder="请输入课程名称搜索"
              :remote-method="searchCourses"
              :loading="courseLoading"
              @change="handleCourseChange"
              clearable>
              <el-option
                v-for="course in courseOptions"
                :key="course.value"
                :label="course.label"
                :value="course.value">
                <span>{{ formatCourseName(course.label) }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">
                  {{ formatCourseCode(course.label) }}
                </span>
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 年级选择 -->
          <el-form-item label="年级">
            <el-select 
              v-model="analysisForm.grade_ids" 
              multiple 
              collapse-tags
              placeholder="请选择年级" 
              clearable>
              <el-option
                v-for="grade in grades"
                :key="grade"
                :label="grade"
                :value="grade">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 班级选择 -->
          <el-form-item label="班级">
            <el-select 
              v-model="analysisForm.class_ids" 
              multiple 
              collapse-tags
              placeholder="请选择班级" 
              @change="handleClassChange"
              clearable>
              <el-option
                v-for="classItem in classes"
                :key="classItem"
                :label="classItem"
                :value="classItem">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 航空公司选择 -->
          <el-form-item label="航空公司">
            <el-select 
              v-model="analysisForm.airline_names" 
              multiple
              collapse-tags
              placeholder="请选择航空公司" 
              clearable>
              <el-option
                v-for="airline in airlines"
                :key="airline"
                :label="airline"
                :value="airline">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 省份选择 -->
          <el-form-item label="省份">
            <el-select 
              v-model="analysisForm.provinces" 
              multiple
              collapse-tags
              placeholder="请选择省份" 
              clearable>
              <el-option
                v-for="province in provinces"
                :key="province"
                :label="province"
                :value="province">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="handleAnalysis" :loading="loading">分析</el-button>
            <el-button @click="resetAnalysis">重置</el-button>
          </el-form-item>
        </el-form>

        <!-- 分析结果表格 -->
        <el-table 
          v-if="analysisData.length > 0"
          :data="analysisData" 
          border 
          @sort-change="handleTableSort"
          style="width: 100%">
          <el-table-column prop="group_name" label="集体名称" width="180" sortable="custom"></el-table-column>
          <el-table-column prop="student_count" label="人数" width="80" sortable="custom">
            <template slot-scope="scope">
              <el-tag type="info">{{ scope.row.student_count }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="avg_score" label="平均分" width="100" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.avg_score.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="median_score" label="中位数" width="100" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.median_score.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="max_score" label="最高分" width="100" sortable="custom"></el-table-column>
          <el-table-column prop="min_score" label="最低分" width="100" sortable="custom"></el-table-column>
          <el-table-column prop="excellent_rate" label="优秀率" width="100" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.excellent_rate.toFixed(2) }}%
            </template>
          </el-table-column>
          <el-table-column prop="pass_rate" label="及格率" sortable="custom">
            <template slot-scope="scope">
              <el-tag :type="scope.row.pass_rate >= 60 ? 'success' : 'danger'">
                {{ scope.row.pass_rate.toFixed(2) }}%
              </el-tag>
            </template>
          </el-table-column>
        </el-table>

        <!-- 图表展示 -->
        <div v-if="analysisData.length > 0" class="charts-container">
          <!-- 柱状图 -->
          <div class="chart-item">
            <div class="chart-header">
              <h4>成绩分布柱状图</h4>
              <el-button size="small" @click="exportChart('bar')">导出图片</el-button>
            </div>
            <div ref="barChart" style="width: 100%; height: 400px;"></div>
          </div>
          <!-- 折线图 -->
          <div class="chart-item">
            <div class="chart-header">
              <h4>优秀率和及格率折线图</h4>
              <el-button size="small" @click="exportChart('line')">导出图片</el-button>
            </div>
            <div ref="lineChart" style="width: 100%; height: 400px;"></div>
          </div>
        </div>

        <!-- 导出按钮 -->
        <div class="export-container" v-if="analysisData.length > 0">
          <el-button-group>
            <el-button type="primary" @click="exportToExcel" :loading="exportLoading">
              导出数据
            </el-button>
            <el-button type="success" @click="exportAllCharts" :loading="chartExporting">
              导出所有图表
            </el-button>
          </el-button-group>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api/index";
import * as echarts from 'echarts';
import { provinces, airlines } from '../../util/constants';

export default {
  name: 'ScoreAnalysis',
  data() {
    return {
      analysisForm: {
        semester_id: '',
        course_id: '',
        class_ids: [],
        grade_ids: [],
        airline_names: [],
        provinces: []
      },
      semesters: [],
      courseOptions: [],
      classes: [],
      grades: [],
      airlines: airlines,
      provinces: provinces,
      loading: false,
      courseLoading: false,
      exportLoading: false,
      chartExporting: false,
      analysisData: [],
      barChart: null,
      lineChart: null,
      currentCourse: null
    }
  },
  methods: {
    // 处理班级选择变化
    handleClassChange() {
      // 如果选择了班级，清空年级选择
      if (this.analysisForm.class_ids.length > 0) {
        this.analysisForm.grade_ids = [];
      }
    },

    // 获取基础数据
    async fetchBaseData() {
      try {
        const [gradesRes, classesRes, semestersRes] = await Promise.all([
          axiosInstance.get(api.getGradeInfo),
          axiosInstance.get(api.getClassInfo),
          axiosInstance.get(api.listSemesters)
        ]);

        this.grades = gradesRes.data.data;
        this.classes = classesRes.data.data;
        this.semesters = semestersRes.data.data;
      } catch (error) {
        this.$message.error('获取基础数据失败');
      }
    },

    // 学期变化时获取课程
    async handleSemesterChange() {
      if (!this.analysisForm.semester_id) {
        this.courseOptions = [];
        return;
      }
      await this.searchCourses('');
    },

    // 搜索课程
    async searchCourses(query) {
      if (!this.analysisForm.semester_id) return;
      
      this.courseLoading = true;
      try {
        const response = await axiosInstance.get(
          api.getSemesterCourses(this.analysisForm.semester_id)
        );
        if (response.data.status === 'success') {
          this.courseOptions = response.data.data.filter(course => 
            course.label.toLowerCase().includes(query.toLowerCase())
          );
        }
      } catch (error) {
        this.$message.error('获取课程列表失败');
      } finally {
        this.courseLoading = false;
      }
    },

    // 执行分析
    async handleAnalysis() {
      if (!this.analysisForm.semester_id) {
        this.$message.warning('请选择学期');
        return;
      }
      // 检查是否至少选择了一个维度
      const hasSelection = 
        this.analysisForm.class_ids.length > 0 ||
        this.analysisForm.grade_ids.length > 0 ||
        this.analysisForm.airline_names.length > 0 ||
        this.analysisForm.provinces.length > 0;

      if (!hasSelection) {
        this.$message.warning('请至少选择一个班级');
        this.$message.warning('请至少选择一个维度（班级/年级/航空公司/省份）');
        return;
      }

      this.loading = true;
      try {
        const response = await axiosInstance.get(api.analyzeScores, {
          params: this.analysisForm
        });
        
        if (response.data.status === 'success') {
          this.analysisData = response.data.data;
          this.$nextTick(() => {
            this.initCharts();
          });
        }
      } catch (error) {
        this.$message.error('分析失败');
      } finally {
        this.loading = false;
      }
    },

    // 重置表单
    resetAnalysis() {
      this.analysisForm = {
        semester_id: '',
        course_id: '',
        class_ids: [],
        grade_ids: [],
        airline_names: [],
        provinces: []
      };
      this.analysisData = [];
      if (this.barChart) this.barChart.dispose();
      if (this.lineChart) this.lineChart.dispose();
    },

    // 初始化图表
    initCharts() {
      this.initBarChart();
      this.initLineChart();
    },

    // 初始化柱状图
    initBarChart() {
      if (this.barChart) this.barChart.dispose();
      this.barChart = echarts.init(this.$refs.barChart);
      
      const courseName = this.currentCourse 
        ? this.formatCourseName(this.currentCourse.label)
        : '全部课程';
      
      const option = {
        title: { text: `${courseName} ` },
        tooltip: { trigger: 'axis' },
        legend: { data: ['平均分', '中位数', '最高分', '最低分'] },
        xAxis: {
          type: 'category',
          data: this.analysisData.map(item => item.group_name)
        },
        yAxis: { type: 'value' },
        series: [
          {
            name: '平均分',
            type: 'bar',
            data: this.analysisData.map(item => item.avg_score)
          },
          {
            name: '中位数',
            type: 'bar',
            data: this.analysisData.map(item => item.median_score)
          },
          {
            name: '最高分',
            type: 'bar',
            data: this.analysisData.map(item => item.max_score)
          },
          {
            name: '最低分',
            type: 'bar',
            data: this.analysisData.map(item => item.min_score)
          }
        ]
      };
      
      this.barChart.setOption(option);
    },

    // 初始化折线图
    initLineChart() {
      if (this.lineChart) this.lineChart.dispose();
      this.lineChart = echarts.init(this.$refs.lineChart);
      
      const courseName = this.currentCourse 
        ? this.formatCourseName(this.currentCourse.label)
        : '全部课程';
      
      const option = {
        title: { text: `${courseName}` },
        tooltip: { trigger: 'axis' },
        legend: { data: ['优秀率', '及格率'] },
        xAxis: {
          type: 'category',
          data: this.analysisData.map(item => item.group_name)
        },
        yAxis: { type: 'value' },
        series: [
          {
            name: '优秀率',
            type: 'line',
            data: this.analysisData.map(item => item.excellent_rate)
          },
          {
            name: '及格率',
            type: 'line',
            data: this.analysisData.map(item => item.pass_rate)
          }
        ]
      };
      
      this.lineChart.setOption(option);
    },

    // 格式化课程名称
    formatCourseName(label) {
      return label.split(' (')[0];
    },
    
    // 格式化课程代码
    formatCourseCode(label) {
      const match = label.match(/\((.*?)\)/);
      return match ? match[1] : '';
    },

    // 处理表格排序
    handleTableSort({ prop, order }) {
      const data = [...this.analysisData];
      data.sort((a, b) => {
        if (order === 'ascending') {
          return a[prop] - b[prop];
        } else if (order === 'descending') {
          return b[prop] - a[prop];
        }
        return 0;
      });
      this.analysisData = data;
    },

    // 导出到Excel
    async exportToExcel() {
      this.exportLoading = true;
      try {
        console.log('Starting export...');
        console.log('Analysis data:', this.analysisData);
        console.log('Current course:', this.currentCourse);

        // 准备CSV数据
        const headers = ['集体名称', '课程名称', '课程代码', '人数', '平均分', '中位数', '最高分', '最低分', '优秀率', '及格率'];
        const rows = this.analysisData.map(item => [
          item.group_name,
          this.currentCourse ? this.formatCourseName(this.currentCourse.label) : '全部课程',
          this.currentCourse ? this.formatCourseCode(this.currentCourse.label) : '-',
          item.student_count,
          Number(item.avg_score).toFixed(2),
          Number(item.median_score).toFixed(2),
          item.max_score,
          item.min_score,
          Number(item.excellent_rate).toFixed(2) + '%',
          Number(item.pass_rate).toFixed(2) + '%'
        ]);

        // 创建CSV内容
        const csvContent = [
          headers.join(','),
          ...rows.map(row => row.join(','))
        ].join('\n');

        // 创建Blob对象
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        
        // 创建下载链接
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `成绩分析报告_${new Date().toISOString().split('T')[0]}.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        this.$message.success('导出成功');
      } catch (error) {
        console.error('Export error:', error);
        this.$message.error('导出失败: ' + error.message);
      } finally {
        this.exportLoading = false;
      }
    },

    // 处理课程选择变化
    handleCourseChange(value) {
      this.currentCourse = this.courseOptions.find(course => course.value === value);
    },

    // 导出单个图表
    exportChart(type) {
      const chart = type === 'bar' ? this.barChart : this.lineChart;
      const title = type === 'bar' ? '成绩分布' : '优秀率及格率';
      const courseName = this.currentCourse 
        ? this.formatCourseName(this.currentCourse.label)
        : '全部课程';
      
      try {
        const url = chart.getDataURL({
          pixelRatio: 2,
          backgroundColor: '#fff'
        });
        
        const link = document.createElement('a');
        link.download = `${courseName}_${title}_${new Date().toISOString().split('T')[0]}.png`;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        this.$message.success('图表导出成功');
      } catch (error) {
        console.error('Chart export error:', error);
        this.$message.error('图表导出失败');
      }
    },

    // 导出所有图表
    async exportAllCharts() {
      this.chartExporting = true;
      try {
        await this.exportChart('bar');
        await this.exportChart('line');
      } finally {
        this.chartExporting = false;
      }
    }
  },
  mounted() {
    this.fetchBaseData();
  },
  beforeDestroy() {
    if (this.barChart) this.barChart.dispose();
    if (this.lineChart) this.lineChart.dispose();
  }
}
</script>

<style scoped>
.analysis-form {
  margin-bottom: 20px;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chart-header h4 {
  margin: 0;
  font-size: 16px;
  color: #303133;
}

.charts-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.chart-item {
  flex: 1;
  min-width: 300px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  padding: 20px;
}

.el-select {
  width: 200px;
}

.el-form-item {
  margin-right: 20px;
  margin-bottom: 15px;
}

.export-container {
  margin-top: 20px;
  text-align: right;
}
</style> 