import Vue from 'vue'
import Router from 'vue-router'

import LoginPage from './components/page/LoginPage'
import UserLayout from './components/page/UserLayout'

import Board from "./components/board/Board.vue";
import UserEdit from "./components/page/UserEdit.vue";
import StudentUpload from "./components/page/StudentUpload.vue";
import StudentManage from "./components/page/StudentManage.vue";
import AdminManage from "./components/page/AdminManage.vue";
import ScoreManage from "./components/page/ScoreManage.vue";
import ScoreCompare from "./components/page/ScoreCollect.vue";
import OpHistory from "./components/page/OpHistory.vue";
import ApplyRecord from "./components/page/ApplyRecord.vue";
import CategoryManage from "./components/page/CategoryManage.vue";
import Semester from "./components/page/Semester.vue";
import ResetPassword from "./components/page/ResetPassword.vue";
import ClassManage from "./components/page/ClassManage.vue";
import StudentScoreMain from "./components/page/StudentScore.vue";
import ScoreAnalysis from "./components/page/ScoreAnalysis.vue";
import VerticalAnalysis from "./components/page/VerticalAnalysis.vue";
import BonusApproval from './components/page/BonusApproval.vue'
import BonusCategoryManage from './components/page/BonusCategoryManage.vue'
import BonusStats from './components/page/BonusStats.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/buaa_admin/',
    routes: [
        {
            path: '/',
            name: 'index',
            redirect: '/board',
            component: UserLayout,
            children: [
                {
                    path: '/board',
                    component: Board
                },
                {
                    path: '/student_import',
                    component: StudentUpload
                },
                {
                    path: '/student_manage',
                    component: StudentManage,
                },
                {
                    path: '/admin_manage',
                    component: AdminManage,
                },
                {
                    path: '/score_manage',
                    component: ScoreManage,
                },
                {
                    path: '/score_compare',
                    component: ScoreCompare,
                },
                {
                    path: '/op_history',
                    component: OpHistory,
                },
                {
                    path: '/apply_record',
                    component: ApplyRecord
                },
                {
                    path: '/category_manage',
                    component: CategoryManage,
                },
                {
                    path: '/user/edit',
                    component: UserEdit
                },
                {
                    path: '/semester_manage',
                    component: Semester
                },
                {
                    path: '/reset_password',
                    component: ResetPassword
                },
                {
                    path: '/class_manage',
                    component: ClassManage,
                },
                {
                    path: '/student_score_main',
                    component: StudentScoreMain,
                },
                {
                    path: '/score_analysis',
                    component: ScoreAnalysis,
                },
                {
                    path: '/vertical_analysis',
                    component: VerticalAnalysis,
                },
                {
                    path: '/bonus_approval',
                    name: 'bonus_approval',
                    component: BonusApproval
                },
                {
                    path: '/bonus_category_manage',
                    name: 'bonus_category_manage',
                    component: BonusCategoryManage
                },
                {
                    path: '/bonus_stats',
                    name: 'bonus_stats',
                    component: BonusStats
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: LoginPage
        }

        // {
        //   path: '/about',
        //   name: 'about',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        // }
    ]
})