<template>
  <div class="bonus-stats-container">
    <div class="page-header">
      <h2 class="page-title">综合素质分统计</h2>
    </div>

    <div class="page-content">
      <!-- 筛选条件 -->
      <div class="filter-section">
        <el-form :inline="true" :model="filterForm" class="filter-form">
          <el-form-item label="学号">
            <el-input v-model="filterForm.student_number" placeholder="请输入学号" clearable></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="filterForm.student_name" placeholder="请输入姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="年级">
            <el-select 
              v-model="filterForm.grade_ids" 
              multiple 
              collapse-tags
              placeholder="请选择年级" 
              class="f-width">
              <el-option
                v-for="grade in grades"
                :key="grade"
                :label="grade"
                :value="grade">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级">
            <el-select 
              v-model="filterForm.class_ids" 
              multiple 
              collapse-tags
              placeholder="请选择班级" 
              class="f-width">
              <el-option
                v-for="classItem in classes"
                :key="classItem"
                :label="classItem"
                :value="classItem">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleFilter">查询</el-button>
            <el-button @click="resetFilter">重置</el-button>
            <el-button type="primary" icon="el-icon-document-delete" @click="exportToExcel" :loading="exportLoading">导出Excel</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 统计表格 -->
      <el-table
        v-loading="loading"
        :data="statsList"
        style="width: 100%"
        border
        :header-cell-style="{background:'#f5f7fa', color:'#606266', fontWeight: '500'}"
        empty-text="暂无数据"
        @sort-change="handleTableSort">
        <el-table-column
          prop="student_number"
          label="学号"
          width="120">
        </el-table-column>
        <el-table-column
          prop="student_name"
          label="姓名"
          width="100">
        </el-table-column>
        <el-table-column
          prop="class_id"
          label="班级"
          width="150">
        </el-table-column>
        <el-table-column
          prop="grade_id"
          label="年级"
          width="100">
        </el-table-column>
        <el-table-column
          prop="total_points"
          label="总得分"
          width="100">
          <template slot-scope="scope">
            <span :class="{'highlight-score': scope.row.total_points > 0}">
              {{ scope.row.total_points.toFixed(1) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="approved_count"
          label="已通过申请"
          width="120">
        </el-table-column>
        <el-table-column
          prop="total_count"
          label="总申请数"
          width="120">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="viewDetail(scope.row)"
              plain>
              查看详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 详情对话框 -->
    <el-dialog
      title="学生综合素质分详情"
      :visible.sync="detailDialogVisible"
      width="80%">
      <div class="detail-content" v-loading="detailLoading">
        <div class="student-info" v-if="studentDetail">
          <el-descriptions :column="3" border>
            <el-descriptions-item label="学号">{{ studentDetail.student_number }}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{ studentDetail.student_name }}</el-descriptions-item>
            <el-descriptions-item label="班级">{{ studentDetail.class_id }}</el-descriptions-item>
            <el-descriptions-item label="年级">{{ studentDetail.grade_id }}</el-descriptions-item>
            <el-descriptions-item label="总得分">
              <span class="highlight-score">{{ studentDetail.total_points.toFixed(1) }}</span>
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div class="applications-table" v-if="studentDetail">
          <h3>申请记录</h3>
          <el-table
            :data="studentDetail.applications"
            style="width: 100%"
            border>
            <el-table-column
              prop="bonus_item"
              label="加分项目"
              min-width="150">
            </el-table-column>
            <el-table-column
              prop="category_name"
              label="加分类别"
              min-width="150">
            </el-table-column>
            <el-table-column
              prop="level"
              label="级别"
              width="100">
            </el-table-column>
            <el-table-column
              prop="applied_bonus_points"
              label="申请分数"
              width="100">
            </el-table-column>
            <el-table-column
              prop="real_bonus_points"
              label="实际得分"
              width="100">
              <template slot-scope="scope">
                {{ scope.row.real_bonus_points !== null ? scope.row.real_bonus_points : '待定' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="application_date"
              label="申请日期"
              width="120">
            </el-table-column>
            <el-table-column
              prop="review_status"
              label="状态"
              width="100">
              <template slot-scope="scope">
                <el-tag :type="getStatusType(scope.row.review_status)">
                  {{ scope.row.review_status }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="证明材料"
              width="100">
              <template slot-scope="scope">
                <el-button 
                  type="text" 
                  @click="viewFile(scope.row.url)"
                  v-if="scope.row.url">
                  查看
                </el-button>
                <span v-else>无</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <!-- 图片查看器对话框 -->
    <el-dialog
      v-if="isImageVisible"
      :visible.sync="isImageVisible"
      width="95%"
      @close="handleImgClose">
      <el-image :src="imageSrc" fit="contain" class="f-width-a"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api";
import * as xlsx from 'xlsx';
import { fetchClasses, fetchGrades } from "../../api/api_util";


export default {
  name: 'BonusStats',
  data() {
    return {
      loading: false,
      detailLoading: false,
      exportLoading: false,
      filterForm: {
        student_number: '',
        student_name: '',
        class_ids: [],
        grade_ids: []
      },
      statsList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      detailDialogVisible: false,
      studentDetail: null,
      isImageVisible: false,
      imageSrc: '',
      // 班级和年级数据
      classes: [],
      grades: [],
      sortField: 'total_points',
      sortOrder: 'desc'
    };
  },
  methods: {
    // 获取班级和年级数据
    async fetchClassesAndGrades() {
      try {
        this.classes = await fetchClasses();
        this.grades = await fetchGrades();
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    
    // 查询数据
    async fetchStats() {
      this.loading = true;
      try {
        // 构建查询参数
        const params = {
          page: this.currentPage,
          page_size: this.pageSize
        };
        
        // 添加筛选条件
        if (this.filterForm.student_number) {
          params.student_number = this.filterForm.student_number;
        }
        
        if (this.filterForm.student_name) {
          params.student_name = this.filterForm.student_name;
        }
        
        if (this.filterForm.class_ids && this.filterForm.class_ids.length > 0) {
          params.class_ids = this.filterForm.class_ids.join(',');
        }
        
        if (this.filterForm.grade_ids && this.filterForm.grade_ids.length > 0) {
          params.grade_ids = this.filterForm.grade_ids.join(',');
        }
        
        // 添加排序参数
        if (this.sortField) {
          params.sort_field = this.sortField;
          params.sort_order = this.sortOrder;
        }
        
        const response = await axiosInstance.get(api.bonusStats, { params });
        
        if (response.data.status === 'success') {
          this.statsList = response.data.data.stats;
          this.total = response.data.data.total;
        } else {
          this.$message.error(response.data.message || '获取数据失败');
        }
      } catch (error) {
        console.error('获取数据失败:', error);
        this.$message.error('获取数据失败');
      } finally {
        this.loading = false;
      }
    },
    
    // 查看详情
    async viewDetail(row) {
      this.detailDialogVisible = true;
      this.detailLoading = true;
      
      try {
        const response = await axiosInstance.get(api.studentBonusDetails(row.student_number));
        
        if (response.data.status === 'success') {
          this.studentDetail = response.data.data;
        } else {
          this.$message.error(response.data.message || '获取详情失败');
        }
      } catch (error) {
        console.error('获取详情失败:', error);
        this.$message.error('获取详情失败');
      } finally {
        this.detailLoading = false;
      }
    },
    
    // 处理筛选
    handleFilter() {
      this.currentPage = 1;
      this.fetchStats();
    },
    
    // 重置筛选
    resetFilter() {
      this.filterForm = {
        student_number: '',
        student_name: '',
        class_ids: [],
        grade_ids: []
      };
      this.currentPage = 1;
      this.fetchStats();
    },
    
    // 处理页码变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.fetchStats();
    },
    
    // 处理每页条数变化
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.fetchStats();
    },
    
    // 导出Excel
    async exportToExcel() {
      this.exportLoading = true;
      try {
        // 获取所有数据
        const params = {};
        
        // 添加筛选条件
        if (this.filterForm.student_number) {
          params.student_number = this.filterForm.student_number;
        }
        
        if (this.filterForm.student_name) {
          params.student_name = this.filterForm.student_name;
        }
        
        if (this.filterForm.grade_ids && this.filterForm.grade_ids.length > 0) {
          params.grade_ids = this.filterForm.grade_ids.join(',');
        }
        
        if (this.filterForm.class_ids && this.filterForm.class_ids.length > 0) {
          params.class_ids = this.filterForm.class_ids.join(',');
        }
        
        // 不分页，获取所有数据
        params.page = 1;
        params.page_size = 10000;
        
        const response = await axiosInstance.get(api.bonusStats, { params });
        
        if (response.data.status === 'success') {
          const data = response.data.data.stats;
          
          // 转换为Excel格式
          const excelData = data.map(item => ({
            学号: item.student_number,
            姓名: item.student_name,
            班级: item.class_id,
            年级: item.grade_id,
            总得分: item.total_points.toFixed(1),
            已通过申请数: item.approved_count,
            总申请数: item.total_count
          }));
          
          // 创建工作表
          const ws = xlsx.utils.json_to_sheet(excelData);
          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "综合素质分统计");
          
          // 导出文件
          xlsx.writeFile(wb, `综合素质分统计_${new Date().toISOString().split('T')[0]}.xlsx`);
          
          this.$message.success('导出成功');
        } else {
          this.$message.error(response.data.message || '导出失败');
        }
      } catch (error) {
        console.error('导出失败:', error);
        this.$message.error('导出失败');
      } finally {
        this.exportLoading = false;
      }
    },
    
    // 查看文件
    viewFile(filePath) {
      if (!filePath) {
        this.$message.warning('没有可查看的文件');
        return;
      }

      let newFilePath = filePath.startsWith('/') ? filePath : '/uploads/' + filePath;
      
      // 检查文件后缀名是否为图片格式
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
      const fileExtension = newFilePath.split('.').pop().toLowerCase();

      if (imageExtensions.includes(fileExtension)) {
        this.imageSrc = newFilePath;
        this.isImageVisible = true;
      } else if (fileExtension === 'pdf') {
        // 如果文件是 PDF，跳转到 PdfViewer 页面
        let pdfPath = "pdf/web/viewer.html?file=" + encodeURIComponent(newFilePath);
        window.open(pdfPath, '_blank');
      } else {
        // 其他类型文件直接下载
        this.downloadFile(filePath);
      }
    },
    
    // 关闭图片查看器
    handleImgClose() {
      this.isImageVisible = false;
    },
    
    // 下载文件
    downloadFile(url) {
      if (url) {
        const fullUrl = url.startsWith('http') ? url : `${window.location.origin}${url}`;
        window.open(fullUrl);
      } else {
        this.$message.warning('没有可下载的文件');
      }
    },
    
    // 获取状态标签类型
    getStatusType(status) {
      const statusMap = {
        '待审核': 'info',
        '审核中': 'warning',
        '已通过': 'success',
        '已拒绝': 'danger'
      };
      return statusMap[status] || 'info';
    },
    
    // 处理表格排序变化
    handleTableSort({ prop, order }) {
      if (prop) {
        this.sortField = prop;
        this.sortOrder = order === 'descending' ? 'desc' : 'asc';
      } else {
        this.sortField = 'total_points';
        this.sortOrder = 'desc';
      }
      this.fetchStats();
    }
  },
  mounted() {
    // 获取班级和年级数据
    this.fetchClassesAndGrades();
    // 初始加载数据
    this.fetchStats();
  }
};
</script>

<style scoped>
.bonus-stats-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  margin-bottom: 20px;
}

.page-title {
  font-size: 22px;
  color: #303133;
  margin: 0;
  font-weight: 500;
}

.filter-section {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.detail-content {
  padding: 10px;
}

.student-info {
  margin-bottom: 20px;
}

.applications-table h3 {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  margin-top: 20px;
  margin-bottom: 15px;
}

.highlight-score {
  color: #f56c6c;
  font-weight: bold;
}

.f-width-a {
  width: 100% !important;
}

.f-width {
  width: 220px;
}

.el-form-item {
  margin-right: 20px;
  margin-bottom: 15px;
}
</style> 