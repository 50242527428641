<template>
  <div class="container">
    <!-- 搜索和操作按钮区域 -->
    <div class="search-container">
      <div class="search-section">
        <el-input
          v-model="query.className"
          placeholder="班级名称"
          class="search-item"
          clearable>
        </el-input>
        <el-select
          v-model="query.grade_id"
          placeholder="高年级"
          clearable
          class="search-item">
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select
          v-model="query.major"
          placeholder="专业"
          clearable
          class="search-item">
          <el-option label="飞行技术" value="飞行技术"></el-option>
          <el-option label="交通运输" value="交通运输"></el-option>
          <el-option label="无人机" value="无人机"></el-option>
        </el-select>
        <el-select
          v-model="query.status"
          placeholder="班级状态"
          clearable
          class="search-item">
          <el-option label="理论阶段" value="理论阶段"></el-option>
          <el-option label="航校阶段" value="航校阶段"></el-option>
          <el-option label="毕设阶段" value="毕设阶段"></el-option>
          <el-option label="已毕业" value="已毕业"></el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          class="search-button"
          @click="handleSearch">
          搜索
        </el-button>
        <el-button 
          type="primary" 
          icon="el-icon-plus" 
          class="add-button"
          @click="handleAdd">
          新增班级
        </el-button>
      </div>
    </div>

    <!-- 表格区域 -->
    <el-table 
      :data="tableData" 
      border 
      class="table" 
      ref="multipleTable"
      :default-sort="{prop: 'class_id', order: 'ascending'}"
      @sort-change="handleSortChange">
      <el-table-column 
        prop="class_id" 
        label="班级名称" 
        sortable="custom"
        min-width="120">
        <template slot-scope="scope">
          <span class="class-id">{{ scope.row.class_id }}</span>
        </template>
      </el-table-column>
      <el-table-column 
        prop="grade_id" 
        label="所属年级" 
        sortable="custom"
        min-width="100">
        <template slot-scope="scope">
          <el-tag size="medium" effect="plain">{{ scope.row.grade_id }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column 
        prop="status" 
        label="班级状态"
        min-width="100">
        <template slot-scope="scope">
          <el-tag 
            :type="getStatusType(scope.row.status)" 
            effect="light"
            size="medium">
            {{ scope.row.status ? scope.row.status : '未设置' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column 
        prop="major" 
        label="专业"
        min-width="120">
        <template slot-scope="scope">
          <span class="major">{{ scope.row.major ? scope.row.major : '未设置' }}</span>
        </template>
      </el-table-column>
      <el-table-column 
        prop="student_count" 
        label="学生人数" 
        width="100"
        align="center">
        <template slot-scope="scope">
          <el-tag 
            size="medium" 
            :type="scope.row.student_count > 0 ? 'success' : 'info'"
            effect="plain">
            {{ scope.row.student_count }} 人
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column 
        label="辅导员" 
        min-width="200">
        <template slot-scope="scope">
          <div class="admin-tags">
            <template v-if="scope.row.admins && scope.row.admins.length">
              <el-tag
                v-for="admin in scope.row.admins"
                :key="admin.admin_id"
                size="medium"
                class="admin-tag"
                effect="plain"
                type="info">
                <i class="el-icon-user"></i>
                {{ admin.name }}
                <el-tooltip effect="light" placement="top">
                  <div slot="content" class="admin-tooltip">
                    <p><i class="el-icon-phone"></i> {{ admin.phone_number }}</p>
                    <p v-if="admin.email"><i class="el-icon-message"></i> {{ admin.email }}</p>
                  </div>
                  <i class="el-icon-info-circle ml5"></i>
                </el-tooltip>
              </el-tag>
            </template>
            <el-tag
              v-else
              size="medium"
              type="warning"
              effect="light">
              <i class="el-icon-warning-outline mr5"></i>暂无辅导员
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column 
        label="操作">
        <template slot-scope="scope">
          <div class="operation-group">
            <el-button-group>
              <el-button 
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)">
              </el-button>
              <el-button 
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)">
              </el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="query.pageIndex"
        :page-size="query.pageSize"
        :total="pageTotal"
        @current-change="handlePageChange"
        @size-change="handleSizeChange"
      ></el-pagination>
    </div>

    <!-- 编辑/新增弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="班级名称" prop="class_id">
          <el-input v-model="form.class_id" placeholder="例如：211611、2024秋华设班"></el-input>
        </el-form-item>
        <el-form-item label="所属年级" prop="grade_id">
          <div class="grade-input-group">
            <el-select v-model="form.grade_id" placeholder="请选择年级" style="width: calc(100% - 100px)">
              <el-option
                v-for="item in gradeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button 
              type="primary" 
              icon="el-icon-plus"
              size="small"
              @click="handleAddGrade">
              新建年级
            </el-button>
          </div>
        </el-form-item>
        <el-form-item label="班级状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态" style="width: 100%">
            <el-option label="理论阶段" value="理论阶段"></el-option>
            <el-option label="航校阶段" value="航校阶段"></el-option>
            <el-option label="毕设阶段" value="毕设阶段"></el-option>
            <el-option label="已毕业" value="已毕业"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <el-select v-model="form.major" placeholder="请选择专业" style="width: 100%">
            <el-option label="飞行技术" value="飞行技术"></el-option>
            <el-option label="交通运输" value="交通运输"></el-option>
            <el-option label="无人机" value="无人机"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导员" prop="admin_ids">
          <el-select 
            v-model="form.admin_ids" 
            multiple 
            placeholder="请选择辅导员" 
            style="width: 100%">
            <el-option
              v-for="admin in adminOptions"
              :key="admin.admin_id"
              :label="admin.name"
              :value="admin.admin_id">
              <span>{{ admin.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">
                {{ admin.phone_number }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生信息">
          <div class="student-info">
            <!-- 编辑班级时显示原有学生 -->
            <div class="student-section" v-if="dialogTitle === '编辑班级'">
              <div class="section-header">
                <div class="section-title">
                  <span>原有学生</span>
                  <el-tag type="info" size="small">{{ classDetail.students.length }} 人</el-tag>
                </div>
              </div>
              <div class="student-list" v-if="classDetail.students.length > 0">
                <el-tag
                  v-for="student in classDetail.students"
                  :key="student.student_number"
                  size="small"
                  class="student-tag">
                  {{ student.name }}
                </el-tag>
              </div>
              <div v-else class="empty-tip">暂无学生</div>
            </div>

            <!-- 新增/编辑班级时都显示新增学生部分 -->
            <div class="student-section">
              <div class="section-header">
                <div class="section-title">
                  <span>{{ dialogTitle === '编辑班级' ? '新增学生' : '选择学生' }}</span>
                  <el-tag type="success" size="small">{{ selectedStudents.length }} 人</el-tag>
                </div>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="small"
                  @click="handleAddStudents">
                  选择学生
                </el-button>
              </div>
              <div class="student-list" v-if="selectedStudents.length > 0">
                <el-tag
                  v-for="student in selectedStudents"
                  :key="student.student_number"
                  size="small"
                  closable
                  @close="removeSelectedStudent(student)"
                  class="student-tag">
                  {{ student.name }}
                </el-tag>
              </div>
              <div v-else class="empty-tip">
                {{ dialogTitle === '编辑班级' ? '暂未选择新学生' : '暂未选择学生' }}
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveClass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加学生对话框 -->
    <el-dialog title="添加学生" :visible.sync="studentDialogVisible" width="70%">
      <div class="search-section mb20">
        <el-select v-model="studentQuery.searchType" placeholder="搜索类型" class="search-item">
          <el-option label="姓名" :value="1"></el-option>
          <el-option label="学号" :value="2"></el-option>
          <el-option label="班级" :value="3"></el-option>
          <el-option label="年级" :value="4"></el-option>
        </el-select>
        <el-input
          v-if="studentQuery.searchType === 1"
          v-model="studentQuery.name"
          placeholder="请输入姓名"
          class="search-item"
          clearable>
        </el-input>
        <el-input
          v-if="studentQuery.searchType === 2"
          v-model="studentQuery.student_id"
          placeholder="请输入学号"
          class="search-item"
          clearable>
        </el-input>
        <el-input
          v-if="studentQuery.searchType === 3"
          v-model="studentQuery.class_id"
          placeholder="请输入班级"
          class="search-item"
          clearable>
        </el-input>
        <el-input
          v-if="studentQuery.searchType === 4"
          v-model="studentQuery.grade_id"
          placeholder="请输入年级"
          class="search-item"
          clearable>
        </el-input>
        
        <el-select
          v-model="studentQuery.provinces"
          multiple
          collapse-tags
          placeholder="选择省份"
          class="search-item">
          <el-option
            v-for="item in provinceOptions"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
        
        <el-select
          v-model="studentQuery.airline_names"
          multiple
          collapse-tags
          placeholder="选择航空公司"
          class="search-item">
          <el-option
            v-for="item in airlineOptions"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
        
        <el-button type="primary" @click="searchStudents">搜索</el-button>
      </div>
      
      <!-- 学生列表 -->
      <el-table
        :data="studentList"
        border
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="student_number" label="学号"></el-table-column>
        <el-table-column prop="class_id" label="当前班级"></el-table-column>
        <el-table-column prop="grade_id" label="年级"></el-table-column>
        <el-table-column prop="province" label="省份"></el-table-column>
        <el-table-column prop="airline_name" label="航空公司"></el-table-column>
      </el-table>
      
      <!-- 分页器 -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="studentQuery.page_num"
          :page-size="studentQuery.page_total"
          :total="studentTotal"
          @current-change="handleStudentPageChange">
        </el-pagination>
      </div>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="studentDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddStudents">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增年级对话框 -->
    <el-dialog title="新建年级" :visible.sync="gradeDialogVisible" width="30%">
      <el-form ref="gradeForm" :model="gradeForm" :rules="gradeRules">
        <el-form-item label="年级" prop="grade_id">
          <el-input v-model="gradeForm.grade_id" placeholder="请输入年级，如：2024"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="gradeDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmAddGrade">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '../../api/index';
import axiosInstance from "../../api/axiosInstance";  // 假设你有这个实例配置
import { provinces, airlines } from '../../util/constants'

export default {
  name: 'ClassManage',
  data() {
    return {
      query: {
        className: '',
        grade_id: '',
        major: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        sortBy: 'class_id',  // 默认按班级名称排序
        sortOrder: 'asc'     // 默认升序
      },
      tableData: [],
      pageTotal: 0,
      dialogVisible: false,
      dialogTitle: '',
      form: {
        class_id: '',
        grade_id: '',
        status: '',
        major: '',
        admin_ids: []
      },
      rules: {
        class_id: [{ required: true, message: '请输入班级名称', trigger: 'blur' }],
        grade_id: [{ required: true, message: '请选择所属年级', trigger: 'change' }],
      },
      gradeOptions: [],
      classDetail: {
        student_count: 0,
        students: [],
        admins: []
      },
      studentDialogVisible: false,
      studentQuery: {
        searchType: 1,
        name: '',
        student_id: '',
        class_id: '',
        grade_id: '',
        provinces: [],
        airline_names: [],
        page_num: 1,
        page_total: 10
      },
      studentList: [],
      studentTotal: 0,
      selectedStudents: [],
      provinceOptions: provinces,
      airlineOptions: airlines,
      currentClassInfo: null, // 当前操作的班级信息
      gradeDialogVisible: false,
      gradeForm: {
        grade_id: ''
      },
      gradeRules: {
        grade_id: [{ required: true, message: '请输入年级', trigger: 'blur' }]
      },
      adminOptions: [],
    }
  },
  created() {
    this.getData();
    this.getGradeOptions();
    this.getAdminOptions();
  },
  methods: {
    // 处理每页显示数量变化
    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getData();
    },
    // 获取年级选项
    async getGradeOptions() {
      try {
        const response = await axiosInstance.get(api.getGradeInfo);
        if (response.data.status === 'success') {
          this.gradeOptions = response.data.data.map(grade => ({
            label: grade,
            value: grade
          }));
        }
      } catch (error) {
        this.$message.error('获取年级信息失败');
      }
    },
    // 获取班级列表数据
    async getData() {
      try {
        const response = await axiosInstance.get(api.getClassList, {
          params: {
            pageIndex: this.query.pageIndex,
            pageSize: this.query.pageSize,
            className: this.query.className,
            grade_id: this.query.grade_id,
            major: this.query.major,
            status: this.query.status,
            sortBy: this.query.sortBy,
            sortOrder: this.query.sortOrder
          }
        });
        
        if (response.data.status === 'success') {
          this.tableData = response.data.data.list;
          this.pageTotal = response.data.data.total;
        } else {
          this.$message.error(response.data.message || '获取数据失败');
        }
      } catch (error) {
        this.$message.error('获取数据失败');
      }
    },
    // 搜索
    handleSearch() {
      this.query.pageIndex = 1
      this.getData()
    },
    // 新增班级
    handleAdd() {
      this.dialogTitle = '新增班级'
      this.form = {
        class_id: '',
        grade_id: '',
        status: '',
        major: '',
        admin_ids: []
      }
      this.selectedStudents = [] // 清空已选学生
      this.dialogVisible = true
    },
    // 编辑班级
    async handleEdit(row) {
      this.dialogTitle = '编辑班级'
      this.form = {
        class_id: row.class_id,
        grade_id: row.grade_id,
        status: row.status,
        major: row.major,
        admin_ids: row.admins.map(admin => admin.admin_id)
      }
      this.dialogVisible = true
      
      // 获取班级详情
      try {
        const response = await axiosInstance.get(api.getClassDetail, {
          params: { class_id: row.class_id }
        });
        if (response.data.status === 'success') {
          this.classDetail = response.data.data;
          // 清空新增学生列表
          this.selectedStudents = [];
        } else {
          this.$message.error(response.data.message || '获取班级详情失败');
        }
      } catch (error) {
        this.$message.error('获取班级详情失败');
      }
    },
    // 删除班级
    handleDelete(row) {
      this.$confirm('确定要删除该班级吗？', '提示', {
        type: 'warning'
      }).then(async () => {
        try {
          const response = await axiosInstance.post(api.deleteClass, {
            class_id: row.class_id
          });
          
          if (response.data.status === 'success') {
            this.$message.success('删除成功');
            this.getData();
          } else {
            this.$message.error(response.data.message || '删除失败');
          }
        } catch (error) {
          this.$message.error('删除失败');
        }
      }).catch(() => {});
    },
    // 保存班级信息
    async saveClass() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const url = this.dialogTitle === '新增班级' ? api.createClass : api.editClass;
            const data = {
              ...this.form,
              student_numbers: this.selectedStudents.map(s => s.student_number)  // 只发送新增的学生
            };
            
            const response = await axiosInstance.post(url, data);
            
            if (response.data.status === 'success') {
              this.$message.success(this.dialogTitle + '成功');
              this.dialogVisible = false;
              this.getData();
              this.selectedStudents = []; // 清空已选学生
              this.classDetail.students = []; // 清空原有学生
            } else {
              this.$message.error(response.data.message || this.dialogTitle + '失败');
            }
          } catch (error) {
            this.$message.error(this.dialogTitle + '失败');
          }
        }
      });
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData()
    },
    // 打开添加学生对话框
    handleAddStudents() {
      this.currentClassInfo = this.form
      this.studentDialogVisible = true
      this.searchStudents()
    },
    
    // 搜索学生
    async searchStudents() {
      try {
        const response = await axiosInstance.post(api.searchStudents, {
          searchType: this.studentQuery.searchType,
          name: this.studentQuery.name,
          student_id: this.studentQuery.student_id,
          class_id: this.studentQuery.class_id,
          grade_id: this.studentQuery.grade_id,
          provinces: this.studentQuery.provinces,
          airline_names: this.studentQuery.airline_names,
          page_num: this.studentQuery.page_num,
          page_total: this.studentQuery.page_total
        })
        
        if (response.data.status === 'success') {
          this.studentList = response.data.data.students
          this.studentTotal = response.data.data.total
        } else {
          this.$message.error(response.data.message || '获取学生列表失败')
        }
      } catch (error) {
        this.$message.error('获取学生列表失败')
      }
    },
    
    // 处理学生选择变化
    handleSelectionChange(val) {
      this.selectedStudents = val
    },
    
    // 处理学生列表分页
    handleStudentPageChange(val) {
      this.studentQuery.page_num = val
      this.searchStudents()
    },
    
    // 确认添加学生
    async confirmAddStudents() {
      if (this.selectedStudents.length === 0) {
        this.$message.warning('请选择要添加的学生')
        return
      }
      
      try {
        const promises = this.selectedStudents.map(student => 
          axiosInstance.post(api.editStudentData, {
            student_number: student.student_number,
            class_id: this.currentClassInfo.class_id,
            grade_id: this.currentClassInfo.grade_id
          })
        )
        
        await Promise.all(promises)
        this.$message.success('添加学生成功')
        this.studentDialogVisible = false
        
        // 刷新班级列表
        await this.getData()
        
        // 将新选择的学生添加到 selectedStudents 中
        this.selectedStudents = [
          ...this.selectedStudents.map(student => ({
            student_number: student.student_number,
            name: student.name
          }))
        ]
      } catch (error) {
        this.$message.error('添加学生失败')
      }
    },
    // 处理排序变化
    handleSortChange({ prop, order }) {
      this.query.sortBy = prop
      this.query.sortOrder = order === 'ascending' ? 'asc' : 'desc'
      this.getData()
    },
    // 打开新增年级对话框
    handleAddGrade() {
      this.gradeDialogVisible = true
      this.gradeForm.grade_id = ''
    },
    // 确认新增年级
    async confirmAddGrade() {
      this.$refs.gradeForm.validate(async valid => {
        if (valid) {
          try {
            const response = await axiosInstance.post(api.createGrade, this.gradeForm);
            if (response.data.status === 'success') {
              this.$message.success('年级创建成功');
              this.gradeDialogVisible = false;
              await this.getGradeOptions();
              this.form.grade_id = this.gradeForm.grade_id;
            } else {
              this.$message.error(response.data.message || '年级创建失败');
            }
          } catch (error) {
            this.$message.error('年级创建失败');
          }
        }
      });
    },
    // 获取辅导员列表
    async getAdminOptions() {
      try {
        const response = await axiosInstance.get(api.getAdminList);
        if (response.data.status === 'success') {
          this.adminOptions = response.data.data.filter(admin => admin.permission_level === 2);
        }
      } catch (error) {
        this.$message.error('获取辅导员列表失败');
      }
    },
    // 获取状态对应的类型
    getStatusType(status) {
      const typeMap = {
        '理论阶段': 'primary',
        '航校阶段': 'success',
        '毕设阶段': 'warning',
        '已毕业': 'info'
      }
      return typeMap[status] || 'info'
    },
    // 移除已选学生
    removeSelectedStudent(student) {
      const index = this.selectedStudents.findIndex(s => s.student_number === student.student_number);
      if (index !== -1) {
        this.selectedStudents.splice(index, 1);
      }
    },
  }
}
</script>

<style scoped>
/* 新的样式 */
.search-container {
  background-color: #fff;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

.search-section {
  display: flex;
  align-items: center;
  gap: 12px; /* 统一设置间距 */
}

.search-item {
  width: 180px !important;
}

.add-button {
  margin-left: auto !important; /* 将新增按钮推到最右侧 */
  font-weight: 500;
  padding: 10px 20px;
}

.search-button {
  padding: 11px 20px;
}

/* 覆盖 element-ui 的默认样式 */
:deep(.el-input__inner) {
  height: 36px;
  line-height: 36px;
}

:deep(.el-button) {
  border-radius: 4px;
}

/* 保留其他必要的样式... */
.table {
  width: 100%;
  font-size: 14px;
  margin-top: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.05);
}

:deep(.el-table) {
  border-radius: 8px;
  overflow: hidden;
}

:deep(.el-table th) {
  background-color: #f5f7fa !important;
  color: #606266;
  font-weight: 500;
  height: 50px;
}

:deep(.el-table td) {
  padding: 12px 0;
}

.class-id {
  font-weight: 500;
  color: #303133;
}

.major {
  color: #606266;
}

.admin-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.admin-tag {
  display: inline-flex;
  align-items: center;
  margin: 2px;
}

.admin-tooltip {
  font-size: 13px;
  line-height: 1.5;
}

.admin-tooltip p {
  margin: 4px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.ml5 {
  margin-left: 5px;
}

.mr5 {
  margin-right: 5px;
}

.pagination {
  margin: 20px 0;
  text-align: right;
}

/* 可以删除之前的这些样式 */
.handle-box,
.handle-input,
.handle-select,
.mr10 {
  /* 这些可以删除 */
}

/* 添加新的样式 */
.student-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.student-info {
  padding: 15px;
  background-color: #f5f7fa;
  border-radius: 4px;
}

.student-section {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #606266;
}

.student-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
  min-height: 60px;
  max-height: 150px;
  overflow-y: auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e4e7ed;
}

.empty-tip {
  color: #909399;
  text-align: center;
  padding: 15px 0;
  background: #fff;
  border-radius: 4px;
  border: 1px dashed #e4e7ed;
}

.student-tag {
  margin: 4px;
}

.operation-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep(.el-button-group) {
  display: inline-flex;
  align-items: center;
}

:deep(.el-button-group .el-button) {
  padding: 5px 10px;
}

:deep(.el-button-group .el-button:not(:last-child)) {
  margin-right: -1px;
}
</style> 