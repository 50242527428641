<template>
  <div class="mb-2">
    <el-card class="card">
      <div class="col-md-12">
        <div slot="header" class="clearfix">
          <h3 class="card-title">成绩管理</h3>
        </div>

        <!-- 搜索栏 -->
        <el-form :inline="true" :model="searchForm" class="search-form">
          <!-- 开关选项 -->
          <div class="switch-container">
            <el-switch
              v-model="searchForm.failed_only"
              active-text="仅显示挂科"
              class="switch-item">
            </el-switch>
            <el-switch
              v-model="searchForm.student_summary"
              active-text="按学生汇总"
              @change="handleSearch"
              class="switch-item">
            </el-switch>
          </div>

          <!-- 第一行：基本查询条件 -->
          <div class="form-row">
            <el-form-item label="查询维度">
              <el-select 
                v-model="searchForm.dimension" 
                @change="handleDimensionChange" 
                style="width: 160px"
                placeholder="选择查询维度">
                <el-option label="指定年级" value="grade"></el-option>
                <el-option label="指定班级" value="class"></el-option>
                <el-option label="指定学生" value="individual"></el-option>
              </el-select>
            </el-form-item>

            <!-- 动态查询条件 -->
            <template v-if="searchForm.dimension === 'grade'">
              <el-form-item label="年级">
                <el-select 
                  v-model="searchForm.grade_ids" 
                  multiple 
                  style="width: 240px"
                  placeholder="请选择年级">
                  <el-option
                    v-for="grade in grades"
                    :key="grade"
                    :label="grade"
                    :value="grade">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>

            <template v-if="searchForm.dimension === 'class'">
              <el-form-item label="班级">
                <el-select 
                  v-model="searchForm.class_ids" 
                  multiple 
                  style="width: 240px"
                  placeholder="请选择班级">
                  <el-option
                    v-for="classItem in classes"
                    :key="classItem"
                    :label="classItem"
                    :value="classItem">
                  </el-option>
                </el-select>
              </el-form-item>
            </template>

            <template v-if="searchForm.dimension === 'individual'">
              <el-form-item label="查询类型">
                <el-select 
                  v-model="searchForm.query_type" 
                  style="width: 160px"
                  placeholder="选择查询类型">
                  <el-option label="学号" value="student_number"></el-option>
                  <el-option label="姓名" value="student_name"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item v-if="searchForm.query_type">
                <el-input 
                  v-model="searchForm.query_value" 
                  :placeholder="searchForm.query_type === 'student_number' ? '请输入学号' : '请输入姓名'"
                  style="width: 160px">
                </el-input>
              </el-form-item>
            </template>
          </div>

          <!-- 第二行：学期和课程 -->
          <div class="form-row">
            <el-form-item label="课程学期">
              <el-select 
                v-model="searchForm.semester_ids" 
                style="width: 360px"
                placeholder="请选择学期" 
                clearable>
                <el-option
                  v-for="semester in semesters"
                  :key="semester.id"
                  :label="semester.semester_name"
                  :value="semester.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item v-if="searchForm.semester_ids" label="课程">
              <el-select 
                v-model="searchForm.course_ids" 
                multiple 
                filterable
                style="width: 360px"
                placeholder="请输入课程名称或代码搜索" 
                clearable>
                <el-option
                  v-for="course in filteredCourses"
                  :key="course.value"
                  :label="course.label"
                  :value="course.value">
                  <span>{{ formatCourseName(course.label) }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{ formatCourseCode(course.label) }}
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <!-- 第三行：其他筛选条件 -->
          <div class="form-row">
            <el-form-item label="航空公司">
              <el-select 
                v-model="searchForm.airline_names" 
                multiple
                collapse-tags
                style="width: 220px"
                placeholder="请选择航空公司" 
                clearable>
                <el-option
                  v-for="airline in airlines"
                  :key="airline"
                  :label="airline"
                  :value="airline">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="省份">
              <el-select 
                v-model="searchForm.provinces" 
                multiple
                collapse-tags
                style="width: 220px"
                placeholder="请选择省份" 
                clearable>
                <el-option
                  v-for="province in provinces"
                  :key="province"
                  :label="province"
                  :value="province">
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <!-- 操作按钮 -->
          <div class="form-row">
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="resetSearch">重置</el-button>
              <el-button 
                type="success" 
                icon="el-icon-download" 
                @click="exportScores"
                :loading="exporting">
                导出Excel
              </el-button>
            </el-form-item>
          </div>
        </el-form>

        <!-- 成绩表格 -->
        <el-table 
          :data="scoreData" 
          style="width: 100%" 
          v-loading="loading"
          border
          @sort-change="handleSortChange">
          <!-- 学生汇总模式 -->
          <template v-if="searchForm.student_summary">
            <el-table-column prop="student_number" label="学号" width="120" sortable="custom"></el-table-column>
            <el-table-column prop="student_name" label="姓名" width="100" sortable="custom"></el-table-column>
            <el-table-column prop="class_id" label="班级" width="120" sortable="custom"></el-table-column>
            <el-table-column prop="airline_name" label="航空公司" sortable="custom"></el-table-column>
            <el-table-column prop="province" label="省份" sortable="custom"></el-table-column>
            <el-table-column prop="total_courses" label="已录入科目">
              <template slot-scope="scope">
                <el-tag>{{ scope.row.total_courses }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="failed_courses" label="挂科数" sortable="custom">
              <template slot-scope="scope">
                <el-tag :type="scope.row.failed_courses > 0 ? 'danger' : 'success'">
                  {{ scope.row.failed_courses }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="pass_rate" label="通过率" sortable="custom">
              <template slot-scope="scope">
                <el-tag :type="getPassRateTagType(scope.row.pass_rate)">
                  {{ scope.row.pass_rate }}%
                </el-tag>
              </template>
            </el-table-column>
          </template>
          <!-- 详细成绩模式 -->
          <template v-else>
            <el-table-column prop="student_number" label="学号" width="120"></el-table-column>
            <el-table-column prop="student_name" label="姓名" width="100"></el-table-column>
            <el-table-column prop="class_id" label="班级" width="120"></el-table-column>
            <el-table-column prop="airline_name" label="航空公司" width="120"></el-table-column>
            <el-table-column prop="province" label="省份" width="120"></el-table-column>
            <el-table-column prop="course_name" label="课程名称" width="150">
              <template slot-scope="scope">
                {{ scope.row.course_name }}
                <el-tag size="mini" type="info" v-if="scope.row.course_code">
                  {{ scope.row.course_code }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="score_value" label="分数" width="100">
              <template slot-scope="scope">
                <el-tag :type="getScoreTagType(scope.row.score_value)">
                  {{ scope.row.score_value }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="score_type" label="评分类型" width="100">
              <template slot-scope="scope">
                <el-tag size="mini">{{ scope.row.score_type }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="score_category" label="成绩类型" width="100">
              <template slot-scope="scope">
                <el-tag size="mini" :type="getScoreCategoryType(scope.row.score_category)">
                  {{ scope.row.score_category }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleEdit(scope.row)">
                  修改
                </el-button>
              </template>
            </el-table-column>
          </template>
        </el-table>

        <!-- 分页 -->
        <div class="pagination-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <!-- 修改成绩对话框 -->
    <el-dialog 
      title="修改成绩" 
      :visible.sync="editDialogVisible"
      width="30%">
      <el-form :model="editForm" label-width="100px" :rules="rules" ref="editForm">
        <el-form-item label="学号">
          <el-input v-model="editForm.student_number" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="editForm.student_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="课程">
          <el-input v-model="editForm.course_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="评分类型" prop="score_type">
          <el-select 
            v-model="editForm.score_type" 
            placeholder="请选择评分类型"
            @change="handleScoreTypeChange">
            <el-option label="百分制" value="百分制"></el-option>
            <el-option label="五级制" value="五级制"></el-option>
            <el-option label="两级制" value="两级制"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="成绩类型" prop="score_category">
          <el-select v-model="editForm.score_category" placeholder="请选择成绩类型">
            <el-option label="初修" value="初修"></el-option>
            <el-option label="重修" value="重修"></el-option>
            <el-option label="补考" value="补考"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分数" prop="score_value">
          <el-input 
            v-model="editForm.score_value" 
            :placeholder="editForm.score_type === '百分制' ? '请输入0-100之间的分数' : '请输入分数'">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit" :loading="submitting">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api/index";
import { provinces, airlines } from "../../util/constants";

export default {
  data() {
    // 自定义分数验证规则
    const validateScore = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入分数'));
      }
      // 如果是百分制，检查分数范围
      if (this.editForm.score_type === '百分制') {
        const score = parseFloat(value);
        if (isNaN(score)) {
          callback(new Error('请输入有效的数字'));
        } else if (score < 0 || score > 100) {
          callback(new Error('分数必须在0-100之间'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      searchForm: {
        dimension: '',  // 查询维度
        query_type: '', // 个人查询类型
        query_value: '', // 查询值
        grade_ids: [], // 年级ID列表
        class_ids: [], // 班级ID列表
        semester_ids: '',  // 改为单选
        course_ids: [],    // 保持多选
        failed_only: false, // 是否只显示挂科
        airline_names: [],
        provinces: [],
        student_summary: false,  // 是否按学生汇总
      },
      semesters: [],
      airlines,
      provinces,
      scoreData: [],
      loading: false,
      exporting: false,
      submitting: false,
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
      editDialogVisible: false,
      editForm: {
        id: '',
        student_number: '',
        student_name: '',
        course_name: '',
        score_type: '',
        score_category: '',
        score_value: ''
      },
      rules: {
        score_type: [
          { required: true, message: '请选择评分类型', trigger: 'change' }
        ],
        score_category: [
          { required: true, message: '请选择成绩类型', trigger: 'change' }
        ],
        score_value: [
          { required: true, message: '请输入分数', trigger: 'blur' },
          { validator: validateScore, trigger: 'blur' }
        ]
      },
      grades: [], // 年级列表
      classes: [], // 班级列表
      courseList: [],
      courseKeyword: '',  // 用于前端筛选
      sortParams: {
        sort_by: '',
        sort_order: ''
      },
    };
  },
  computed: {
    filteredCourses() {
      if (!this.courseKeyword) return this.courseList;
      const keyword = this.courseKeyword.toLowerCase();
      return this.courseList.filter(course => 
        course.label.toLowerCase().includes(keyword)
      );
    }
  },
  mounted() {
    this.fetchSemesters();
    this.fetchGradesAndClasses();
    this.fetchScores();
  },
  watch: {
    'searchForm.semester_ids'(newVal) {
      if (!newVal) {
        this.courseList = [];
        this.searchForm.course_ids = [];
      } else {
        this.fetchCourses();
      }
    }
  },
  methods: {
    // 获取学期列表
    async fetchSemesters() {
      try {
        const response = await axiosInstance.get(api.listSemesters);
        if (response.data.status === 'success') {
          this.semesters = response.data.data;
        }
      } catch (error) {
        this.$message.error('获取学期列表失败');
      }
    },

    // 获取成绩数据
    async fetchScores() {
      this.loading = true;
      try {
        const params = {
          page: this.pagination.currentPage,
          page_size: this.pagination.pageSize,
          dimension: this.searchForm.dimension,
          query_type: this.searchForm.query_type,
          query_value: this.searchForm.query_value,
          semester_ids: this.searchForm.semester_ids,
          course_ids: this.searchForm.course_ids,
          failed_only: this.searchForm.failed_only,
          student_summary: this.searchForm.student_summary,
          grade_ids: this.searchForm.grade_ids,
          class_ids: this.searchForm.class_ids,
          airline_names: this.searchForm.airline_names,
          provinces: this.searchForm.provinces,
          sort_by: this.sortParams.sort_by,
          sort_order: this.sortParams.sort_order
        };

        const response = await axiosInstance.get(api.queryStudentScores, { 
          params,
          paramsSerializer: params => {
            return Object.entries(params).map(([key, value]) => {
              if (Array.isArray(value)) {
                return value.map(v => `${key}[]=${v}`).join('&');
              }
              return `${key}=${value}`;
            }).join('&');
          }
        });
        if (response.data.status === 'success') {
          this.scoreData = response.data.data.items;
          this.pagination.total = response.data.data.total;
        }
      } catch (error) {
        this.$message.error('获取成绩数据失败');
      } finally {
        this.loading = false;
      }
    },

    // 搜索
    handleSearch() {
      this.pagination.currentPage = 1;
      this.fetchScores();
    },

    // 重置搜索
    resetSearch() {
      this.searchForm = {
        dimension: '',
        query_type: '',
        query_value: '',
        grade_ids: [],
        class_ids: [],
        semester_ids: '',
        course_ids: [],
        failed_only: false,
        airline_names: [],
        provinces: [],
        student_summary: false,
      };
      this.handleSearch();
    },

    // 分页处理
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.fetchScores();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.fetchScores();
    },

    // 修改成绩
    handleEdit(row) {
      this.editForm = { ...row };
      this.editDialogVisible = true;
    },

    // 评分类型改变时重新验证分数
    handleScoreTypeChange() {
      // 如果表单已经填写了分数，则重新验证
      if (this.editForm.score_value) {
        this.$refs.editForm.validateField('score_value');
      }
    },

    // 提交修改
    async submitEdit() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          // 如果是百分制，确保分数不超过100且保留一位小数
          if (this.editForm.score_type === '百分制') {
            const score = parseFloat(this.editForm.score_value);
            if (!isNaN(score)) {
              this.editForm.score_value = Math.min(100, score).toFixed(1);
            }
          }
          
          this.submitting = true;
          try {
            const response = await axiosInstance.post(api.updateStudentScore, this.editForm);
            if (response.data.status === 'success') {
              this.$message.success('修改成功');
              this.editDialogVisible = false;
              this.fetchScores();
            }
          } catch (error) {
            this.$message.error('修改失败');
          } finally {
            this.submitting = false;
          }
        }
      });
    },

    // 获取成绩标签类型
    getScoreTagType(score) {
      if (score >= 90) return 'success';
      if (score >= 80) return '';
      if (score >= 60) return 'warning';
      return 'danger';
    },

    // 获取成绩类型标签类型
    getScoreCategoryType(category) {
      switch (category) {
        case '初修': return '';
        case '重修': return 'warning';
        case '补考': return 'danger';
        default: return 'info';
      }
    },

    // 获取通过率标签类型
    getPassRateTagType(rate) {
      if (rate >= 90) return 'success';
      if (rate >= 80) return '';
      if (rate >= 60) return 'warning';
      return 'danger';
    },

    // 获取年级和班级列表
    async fetchGradesAndClasses() {
      try {
        const [gradesRes, classesRes] = await Promise.all([
          axiosInstance.get(api.getGradeInfo),
          axiosInstance.get(api.getClassInfo)
        ]);
        if (gradesRes.data.status === 'success') {
          this.grades = gradesRes.data.data;
        }
        if (classesRes.data.status === 'success') {
          this.classes = classesRes.data.data;
        }
      } catch (error) {
        this.$message.error('获取年级和班级信息失败');
      }
    },

    // 搜索课程
    async fetchCourses() {
      if (!this.searchForm.semester_ids) return;
      
      try {
        const response = await axiosInstance.get(api.getSemesterCourses(this.searchForm.semester_ids));
        if (response.data.status === 'success') {
          this.courseList = response.data.data;
        }
      } catch (error) {
        this.$message.error('获取课程列表失败');
      }
    },

    // 处理查询维度变化
    handleDimensionChange() {
      this.searchForm.query_type = '';
      this.searchForm.query_value = '';
      this.searchForm.grade_ids = [];
      this.searchForm.class_ids = [];
    },

    // 从课程标签中提取课程名称
    formatCourseName(label) {
      return label.split(' (')[0];
    },
    
    // 从课程标签中提取课程代码
    formatCourseCode(label) {
      const match = label.match(/\((.*?)\)/);
      return match ? match[1] : '';
    },

    // 处理排序变化
    handleSortChange({ prop, order }) {
      this.sortParams.sort_by = prop;
      this.sortParams.sort_order = order === 'ascending' ? 'asc' : 'desc';
      this.fetchScores();
    },

    // 导出成绩
    async exportScores() {
      if (this.scoreData.length === 0) {
        this.$message.warning('没有可导出的数据');
        return;
      }

      this.exporting = true;
      try {
        // 构建查询参数
        const params = {
          dimension: this.searchForm.dimension,
          grade_ids: this.searchForm.grade_ids,
          class_ids: this.searchForm.class_ids,
          query_type: this.searchForm.query_type,
          query_value: this.searchForm.query_value,
          semester_ids: this.searchForm.semester_ids,
          course_ids: this.searchForm.course_ids,
          failed_only: this.searchForm.failed_only,
          airline_names: this.searchForm.airline_names,
          provinces: this.searchForm.provinces,
          student_summary: this.searchForm.student_summary,
          sort_by: this.sortParams.sort_by,
          sort_order: this.sortParams.sort_order
        };

        // 发送导出请求
        const response = await axiosInstance.post(api.exportStudentScores, params, {
          responseType: 'blob' // 指定响应类型为二进制数据
        });

        // 创建Blob对象
        const blob = new Blob([response.data], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        });

        // 创建下载链接
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        
        // 设置文件名
        const fileName = `学生成绩_${new Date().toISOString().split('T')[0]}.xlsx`;
        link.setAttribute('download', fileName);
        
        // 触发下载
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        this.$message.success('成绩导出成功');
      } catch (error) {
        console.error('Export error:', error);
        this.$message.error('成绩导出失败');
      } finally {
        this.exporting = false;
      }
    },
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

.switch-container {
  background-color: #f5f7fa;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.switch-item {
  margin-right: 20px;
}

.form-row {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.el-form-item {
  margin-right: 20px;
  margin-bottom: 0;
}

.el-form-item__label {
  width: auto;
  padding-right: 12px;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.el-tag {
  margin-left: 5px;
}

.el-tag--mini {
  height: 18px;
  line-height: 16px;
  padding: 0 4px;
}

.el-select-dropdown__item {
  white-space: normal;
  height: auto;
  line-height: 1.5;
  padding: 8px 20px;
}
</style> 