<template>
  <div class="mb-2">
    <el-card class="card">
      <div class="col-md-12">
        <div slot="header" class="clearfix">
          <h3 class="card-title">学期管理</h3>
          <el-button type="primary" @click="showAddDialog" style="float: right;">添加学期</el-button>
        </div>

        <el-table :data="semesters" style="width: 100%; margin-top: 20px">
          <el-table-column prop="semester_name" label="学期名称"></el-table-column>
          <el-table-column prop="start_date" label="开始日期" sortable></el-table-column>
          <el-table-column prop="end_date" label="结束日期" sortable></el-table-column>
          <el-table-column prop="score_status_text" label="成绩状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.score_status === 1 ? 'success' : 'info'">
                {{ scope.row.score_status === 1 ? '已上传' : '未上传' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220">
            <template slot-scope="scope">
              <el-button-group>
                <el-button 
                  size="mini" 
                  type="primary" 
                  @click="showEditDialog(scope.row)">
                  编辑
                </el-button>
                
                <!-- 成绩操作下拉按钮 -->
                <el-dropdown 
                  @command="handleScoreCommand($event, scope.row)" 
                  trigger="click">
                  <el-button size="mini" type="primary">
                    成绩操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="updateSingle">
                      <i class="el-icon-edit"></i> 单科成绩更新
                    </el-dropdown-item>
                    <el-dropdown-item command="upload">
                      <i class="el-icon-upload2"></i> 上传成绩
                    </el-dropdown-item>
                    <el-dropdown-item 
                      command="history" 
                      :disabled="scope.row.score_status === 0">
                      <i class="el-icon-document"></i> 上传记录
                    </el-dropdown-item>
                    <el-dropdown-item 
                      command="delete" 
                      :disabled="scope.row.score_status === 0"
                      divided>
                      <i class="el-icon-delete"></i> 删除成绩
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>

                <el-button 
                  size="mini" 
                  type="danger" 
                  @click="deleteSemester(scope.row.id)">
                  删除
                </el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 添加/编辑学期对话框 -->
    <el-dialog :visible.sync="dialogVisible" title="学期信息" width="80%">
      <el-form :model="currentSemester" >
        <el-form-item label="学期名称">
          <el-input v-model="currentSemester.semester_name" class="f-width-a"  placeholder="输入学期名称"></el-input>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker v-model="currentSemester.start_date" value-format="yyyy-MM-dd" type="date"
                          class="f-width-a"  placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期">
          <el-date-picker v-model="currentSemester.end_date" value-format="yyyy-MM-dd" type="date"
                          class="f-width-a"  placeholder="选择日期"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveSemester">保存</el-button>
      </span>
    </el-dialog>

    <!-- 上传成绩对话框 -->
    <el-dialog
      title="上传成绩"
      :visible.sync="uploadDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="!uploading">
      <el-form :model="uploadForm" label-width="80px">
        <el-form-item label="上传方式">
          <el-radio-group v-model="uploadForm.uploadType">
            <el-radio :label="1">更新</el-radio>
            <el-radio :label="2">覆盖</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择文件">
          <el-upload
            class="upload-demo"
            :action="''"
            :auto-upload="false"
            :on-change="handleFileChange"
            :file-list="fileList"
            accept=".xlsx,.xls">
            <el-button size="small" type="primary" :disabled="uploading">选择文件</el-button>
            <div slot="tip" class="el-upload__tip">只能上传 xlsx/xls 文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelUpload" :disabled="uploading">取 消</el-button>
        <el-button type="primary" @click="submitUpload" :loading="uploading" :disabled="!canUpload">
          {{ uploading ? '处理中...' : '确 定' }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 上传记录对话框 -->
    <el-dialog 
      title="上传记录" 
      :visible.sync="historyDialogVisible" 
      :close-on-click-modal="!coveringData"
      :close-on-press-escape="!coveringData"
      :show-close="!coveringData"
      width="800px">
      <el-table :data="uploadHistory" v-loading="historyLoading">
        <el-table-column prop="file_name" label="文件名" width="200"></el-table-column>
        <el-table-column prop="upload_type" label="上传类型">
          <template slot-scope="scope">
            {{ scope.row.upload_type === 1 ? '更新' : '覆盖' }}
          </template>
        </el-table-column>
        <el-table-column prop="upload_time" label="上传时间"></el-table-column>
        <el-table-column prop="admin_name" label="上传人"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip content="使用该数据覆盖当前成绩" placement="top">
              <el-button
                size="mini"
                type="warning"
                icon="el-icon-refresh-right"
                circle
                :loading="coveringData && coveringRecordId === scope.row.id"
                :disabled="coveringData"
                @click="handleUseHistoryData(scope.row)">
              </el-button>
            </el-tooltip>
            <el-tooltip content="下载成绩表格" placement="top">
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-download"
                circle
                :disabled="coveringData"
                @click="handleDownloadHistory(scope.row)">
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="coveringData" class="covering-tip">
        <i class="el-icon-loading"></i>
        <span>正在覆盖成绩数据，请勿关闭页面...</span>
      </div>
    </el-dialog>

    <!-- 单科成绩更新对话框 -->
    <el-dialog
      title="单科成绩更新"
      :visible.sync="singleUpdateDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="!uploading">
      <div class="dialog-header-actions">
        <el-button 
          type="text" 
          icon="el-icon-download"
          @click="downloadTemplate">
          下载模板
        </el-button>
      </div>
      <el-form :model="singleUpdateForm" label-width="80px">
        <el-form-item label="学期">
          <el-input :value="currentSemester.semester_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="课程">
          <el-select
            v-model="singleUpdateForm.course"
            placeholder="请选择课程"
            filterable
            :loading="coursesLoading"
            style="width: 100%">
            <el-option
              v-for="course in courseList"
              :key="course.value"
              :label="course.label"
              :value="course.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择文件">
          <el-upload
            class="upload-demo"
            :action="''"
            :auto-upload="false"
            :on-change="handleSingleFileChange"
            :on-remove="handleSingleFileRemove"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="singleFileList"
            accept=".xlsx,.xls">
            <el-button size="small" type="primary" :disabled="uploading">选择文件</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传 xlsx/xls 文件，且必须符合模板格式
              <el-link type="primary" @click="downloadTemplate" style="margin-left: 5px">下载模板</el-link>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSingleUpdate" :disabled="uploading">取 消</el-button>
        <el-button type="primary" @click="submitSingleUpdate" :loading="uploading" :disabled="!canSingleUpload">
          {{ uploading ? '处理中...' : '确 定' }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axiosInstance from "../../api/axiosInstance";
import api from "../../api/index";

export default {
  data() {
    return {
      semesters: [],
      dialogVisible: false,
      isEditMode: false,
      uploadDialogVisible: false,
      historyDialogVisible: false,
      uploading: false,
      uploadForm: {
        uploadType: 1,
        file: null
      },
      uploadHistory: [],
      historyLoading: false,
      uploadHeaders: {
        Authorization: localStorage.getItem('token')
      },
      currentSemester: {
        id: null,
        semester_name: '',
        start_date: '',
        end_date: ''
      },
      api: api,
      fileList: [],
      canUpload: false,
      coveringData: false,
      coveringRecordId: null,
      singleUpdateDialogVisible: false,
      singleUpdateForm: {
        course: null,
        file: null
      },
      singleFileList: [],
      canSingleUpload: false,
      courseList: [],
      coursesLoading: false
    };
  },
  mounted() {
    this.fetchSemesters();
  },
  methods: {
    fetchSemesters() {
      axiosInstance.get(api.listSemesters)
          .then(response => {
            this.semesters = response.data.data;
          })
          .catch(error => {
            this.$message.error('获取学期信息失败: ' + error);
          });
    },
    showAddDialog() {
      this.isEditMode = false;
      this.currentSemester = {
        id: null,
        semester_name: '',
        start_date: '',
        end_date: ''
      };
      this.dialogVisible = true;
    },
    showEditDialog(semester) {
      this.isEditMode = true;
      this.currentSemester = {...semester};
      this.dialogVisible = true;
    },
    saveSemester() {
      if (this.isEditMode) {
        // 更新学期
        axiosInstance.put(api.updateSemester(this.currentSemester.id), this.currentSemester).then(() => {
          this.fetchSemesters();
          this.dialogVisible = false;
          this.$message.success('学期更新成功');
        })
            .catch(error => {
              this.$message.error('更新学期失败: ' + error);
            });
      } else {
        // 创建新学期
        axiosInstance.post(api.createSemester, this.currentSemester)
            .then(() => {
              this.fetchSemesters();
              this.dialogVisible = false;
              this.$message.success('学期创建成功');
            })
            .catch(error => {
              this.$message.error('创建学期失败: ' + error);
            });
      }
    },
    deleteSemester(id) {
      this.$confirm('确定删除此学期吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axiosInstance.delete(api.deleteSemester(id))
            .then(() => {
              this.fetchSemesters();
              this.$message.success('学期删除成功');
            })
            .catch(error => {
              this.$message.error('删除学期失败: ' + error);
            });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    // 显示上传成绩对话框
    handleUploadScore(row) {
      this.uploadForm.semester_id = row.id;
      this.uploadDialogVisible = true;
    },

    // 显示上传历史记录
    async showUploadHistory(row) {
      this.historyLoading = true;
      try {
        const response = await axiosInstance.get(api.getSemesterUploadHistory(row.id));
        if (response.data.status === 'success') {
          this.uploadHistory = response.data.data;
          this.historyDialogVisible = true;
        } else {
          this.$message.error(response.data.message || '获取上传记录失败');
        }
      } catch (error) {
        this.$message.error('获取上传记录失败');
      } finally {
        this.historyLoading = false;
      }
    },

    // 删除成绩
    handleDeleteScore(row) {
      this.$confirm('确定要删除本学期的所有成绩数据吗？此操作不可恢复！', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          const response = await axiosInstance.post(api.deleteSemesterScore(row.id));
          if (response.data.status === 'success') {
            this.$message.success('成绩删除成功');
            this.fetchSemesters();
          } else {
            this.$message.error(response.data.message || '删除失败');
          }
        } catch (error) {
          this.$message.error('成绩删除失败');
        }
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },

    // 上传文件前的验证
    beforeUpload(file) {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
                      file.type === 'application/vnd.ms-excel';
      if (!isExcel) {
        this.$message.error('只能上传 Excel 文件！');
        return false;
      }
      return true;
    },

    handleFileChange(file) {
      this.uploadForm.file = file.raw
      this.canUpload = true
    },
    cancelUpload() {
      this.uploadDialogVisible = false
      this.uploadForm.file = null
      this.fileList = []
      this.canUpload = false
    },
    async submitUpload() {
      if (!this.uploadForm.file) {
        this.$message.error('请选择文件')
        return
      }

      this.uploading = true

      const formData = new FormData()
      formData.append('file', this.uploadForm.file)
      formData.append('semester_id', this.uploadForm.semester_id)
      formData.append('upload_type', this.uploadForm.uploadType)

      try {
        const response = await axiosInstance.post(api.uploadSemesterScore, formData)
        if (response.data.status === 'success') {
          this.$message.success('成绩上传成功')
          this.uploadDialogVisible = false
          this.fetchSemesters()
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        this.$message.error(`上传失败：${error.message}`)
      } finally {
        this.uploading = false
        this.uploadForm.file = null
        this.fileList = []
        this.canUpload = false
      }
    },

    // 处理成绩操作命令
    handleScoreCommand(command, row) {
      switch (command) {
        case 'updateSingle':
          this.handleSingleUpdate(row);
          break;
        case 'upload':
          this.handleUploadScore(row);
          break;
        case 'history':
          this.showUploadHistory(row);
          break;
        case 'delete':
          this.handleDeleteScore(row);
          break;
      }
    },

    // 使用历史数据
    handleUseHistoryData(record) {
      this.$confirm('确定要使用该历史数据吗？这将会覆盖当前的成绩数据！', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.coveringData = true;
          this.coveringRecordId = record.id;
          const response = await axiosInstance.post(
            api.useSemesterScoreHistory(record.id), 
            { semester_id: this.currentSemester.id }
          );
          if (response.data.status === 'success') {
            this.$message.success('成绩数据已更新');
            this.historyDialogVisible = false;
            this.fetchSemesters();
          } else {
            this.$message.error(response.data.message || '更新失败');
          }
        } catch (error) {
          this.$message.error('更新成绩数据失败');
        } finally {
          this.coveringData = false;
          this.coveringRecordId = null;
        }
      }).catch(() => {
        this.$message.info('已取消操作');
      });
    },

    // 下载历史记录表格
    async handleDownloadHistory(record) {
      try {
        if (!record.id) {
          this.$message.error('记录ID不存在');
          return;
        }
        const response = await axiosInstance.get(
          api.downloadSemesterScore(record.id), 
          { responseType: 'blob' }
        );
        
        // 创建下载链接
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', record.file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);  // 清理 URL 对象
      } catch (error) {
        console.error('下载失败:', error);
        this.$message.error('下载文件失败');
      }
    },

    // 单科成绩更新
    async handleSingleUpdate(row) {
      this.currentSemester = row;
      this.coursesLoading = true;
      try {
        const response = await axiosInstance.get(api.getSemesterCourses(row.id));
        if (response.data.status === 'success') {
          this.courseList = response.data.data;
          this.singleUpdateDialogVisible = true;
        } else {
          this.$message.error(response.data.message || '获取课程列表失败');
        }
      } catch (error) {
        this.$message.error('获取课程列表失败');
      } finally {
        this.coursesLoading = false;
      }
    },

    handleSingleFileChange(file, fileList) {
      // 根据文件状态处理
      if (file.status === 'ready') {
        this.singleFileList = fileList;
        this.singleUpdateForm.file = file.raw;
        this.canSingleUpload = this.singleUpdateForm.course && file.raw;
      } else {
        // 如果文件被移除
        this.singleFileList = fileList;
        this.singleUpdateForm.file = null;
        this.canSingleUpload = false;
      }
    },

    cancelSingleUpdate() {
      this.singleUpdateDialogVisible = false;
      this.singleUpdateForm = {
        course: null,
        file: null
      };
      this.singleFileList = [];
      this.canSingleUpload = false;
    },

    async submitSingleUpdate() {
      if (!this.singleUpdateForm.file || !this.singleUpdateForm.course) {
        this.$message.error('请选择课程和文件');
        return;
      }

      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.singleUpdateForm.file);
      formData.append('semester_id', this.currentSemester.id);
      formData.append('course_id', this.singleUpdateForm.course);

      try {
        const response = await axiosInstance.post(api.updateSingleCourse, formData);
        if (response.data.status === 'success') {
          this.$message.success('成绩更新成功');
          this.singleUpdateDialogVisible = false;
          this.fetchSemesters();
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        this.$message.error(`更新失败：${error.message}`);
      } finally {
        this.uploading = false;
        this.singleUpdateForm.file = null;
        this.singleFileList = [];
        this.canSingleUpload = false;
      }
    },

    // 下载单科成绩更新模板
    async downloadTemplate() {
      try {
        const response = await axiosInstance.get(
          api.downloadScoreTemplate,
          { responseType: 'blob' }
        );
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '单科成绩更新模板.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        this.$message.error('下载模板失败');
      }
    },

    handleExceed() {
      this.$message.warning('只能上传一个文件');
    },

    handleSingleFileRemove() {
      this.singleUpdateForm.file = null;
      this.canSingleUpload = false;
    }
  }
};
</script>

<style scoped>
.el-table .el-button {
  margin-right: 10px;
}

.dialog-footer {
  text-align: right;
}

.rounded-card {
  border-radius: 15px; /* 圆角设置 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

.rounded-card .el-card__header {
  border-top-left-radius: 15px; /* 头部左上圆角 */
  border-top-right-radius: 15px; /* 头部右上圆角 */
}

.card-title {
  font-size: 1rem; /* Adjust this to your preferred size */
  margin-bottom: 0.1rem;
}

.rounded-card .el-card__body {
  border-bottom-left-radius: 15px; /* 身体左下圆角 */
  border-bottom-right-radius: 15px; /* 身体右下圆角 */
}
.f-width {
  width: 220px;
}

.f-width-a {
  width: 100%!important;
}

.h-width {
  width: 105px!important;
}

.el-button-group {
  display: flex;
  align-items: center;
}

.el-button-group .el-button {
  margin: 0 !important;
}

.el-dropdown {
  margin: 0 -1px;
}

.el-dropdown-menu i {
  margin-right: 5px;
}

.el-dropdown-menu__item.is-disabled {
  color: #C0C4CC;
  cursor: not-allowed;
}

.upload-demo {
  width: 100%;
}

.upload-type-tip {
  margin-top: 8px;
  color: #909399;
  font-size: 12px;
  line-height: 1.4;
}

.upload-type-tip p {
  margin: 0;
}

.el-upload {
  width: 100%;
}

.el-upload-dragger {
  width: 100%;
}

.history-empty {
  text-align: center;
  color: #909399;
  padding: 30px 0;
}

/* 操作按钮间距 */
.el-table .el-button.is-circle {
  margin: 0 4px;
}

/* 表格内容垂直居中 */
.el-table td {
  padding: 8px 0;
}

.covering-tip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 20px 30px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 2000;
}

.covering-tip i {
  margin-right: 10px;
  font-size: 20px;
  color: #409EFF;
}

.covering-tip span {
  color: #606266;
  font-size: 14px;
}

.dialog-header-actions {
  position: absolute;
  right: 45px;
  top: 20px;
}
</style>